
app-attendance{
  $root: &;

  & .container-100-h{
    height: 100vh;
  }

  & .updating-font-size{
    font-size: 1em;
  }

  & .font-size-5em{
    font-size: 5em;
  }

  & .p-b-6em{
    padding-bottom: 6em;
  }

 & .parent-statuses {
   & > div {
     width: 100%;
   }
 }


  & .sub-status {
    & > div {
      width: 100%;
    }
  }
}

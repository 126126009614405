app-nat-advisor-dashboard {
  $root: &;

  & .nat-filter {
    padding-bottom: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    & p-multiselect {
      width: 22rem;
    }
  }

  & p-calendar {
    & span {
      width: 100%;

      & input {
        width: 100%;
      }
    }
  }

  & p-dropdown > div {
    width: 100%;
  }

  & input {
    height: 2.3rem;
    border: 1px solid #a6a6a6;
    padding: 6px;
    font-size: 15px;
  }

  & .button {

    &.selected{
      font-weight: bold;
      box-shadow: 0 2px 3px 0 rgb(0 0 0 / 60%);
    }

    width: 9rem;

    @include respond(xs) {
      width: 100%;
      margin-top: 0.3rem;
    }

  }


  & .badge {
    font-weight: 400;
    line-height: unset;
    margin: 0;
  }
}

//Dark Mode
body[class*="color-scheme-dark"] {
  app-nat-advisor-dashboard {
    $root: &;

    & input {
      background-color: #3a4452;
      border: none;
      color: #fff;
    }
  }
}

.idleDialog {
  $root: &;

  &>div {
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  }

  &__wrapper {
    text-align: center;
    padding: 0 3rem;

    @include respond(xs) {
      padding: 0;
    }

    & .countDown {
      font-size: 22px;
      font-weight: 900;
      padding: 0 3px;
      color: #414d5f;
      display: inline-block;
    }
  }

  .p-dialog .p-dialog-header {
    border: none;
  }

  .p-dialog .p-dialog-content {
    border: none;
  }

  & &--btn {
    margin: 1.3rem 0 2rem 0;
    width: 13rem;
  }

  &--icon {
    color: #007ad9;
    margin-bottom: 1.2rem;
  }
}

body.color-scheme-dark {
  & .countDown {
    color: #007ad9;
  }
}
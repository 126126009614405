a:hover {
  text-decoration: none;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: inherit;
  color: inherit;
  outline: none !important;
  outline-width: 0;
}

body .p-datatable .p-sortable-column.p-highlight:focus {
  outline: none;
  outline-width: 0;
}

.dropdown-toggle::after {
  display: none;
}

.p-widget,
.p-widget * {
  outline-width: 0 !important;
}

.p-datatable p-sorticon {
  float: right;
}

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary,
body .p-treetable .p-treetable-summary {
  padding: 0.571em 0em;
}

button:focus {
  box-shadow: none;
}

.timeline:not(.timeline--horizontal):before {
  background-color: #35b8e0;
  bottom: 0;
  content: "";
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 1;
}

.timeline__content {
  background-color: #3b4452;
  border: none;
  color: #f7f7f7;
  display: block;
  padding: 1.25rem;
  position: relative;
}

.timeline__content:before {
  border-bottom: 10px solid transparent;
  border-left: 12px solid #3b4452;
  border-top: 10px solid transparent;
}

.timeline__content:after {
  border-bottom: 10px solid transparent;
  border-left: 12px solid #434e5e;
  border-top: 10px solid transparent;
}

.timeline__item--right .timeline__content:after {
  border-bottom: 9px solid transparent;
  border-right: 11px solid #434e5e;
  border-left: none;
  border-top: 9px solid transparent;
}

.timeline__item--right .timeline__content:before {
  border-bottom: 10px solid transparent;
  border-right: 12px solid #3b4452;
  border-left: none;
  border-top: 10px solid transparent;
}

.timeline__item:after {
  background: #3b4452;
  border: 3px solid #35b8e0;
  border-radius: 50%;
  content: "";
  height: 20px;
  position: absolute;
  right: -10px;
  transform: translateY(-50%);
  top: 50%;
  width: 20px;
  z-index: 1;
}

p-dialog > div {
  box-shadow: none !important;
}

#filterCohortsTransparent > div,
#filterCohortsTransparent .p-multiselect .p-multiselect-trigger,
#filterCohortsTransparent
  > div
  > div.ng-tns-c20-5.p-multiselect-trigger.p-state-default.p-corner-right {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
  border: none;
}

#filterCohortsTransparent > div > div.p-multiselect-label-container > span {
  color: #ffffff;
}

#filterCohortsTransparent
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item,
#filterCohortsTransparent .p-multiselect-panel {
  background-color: #1bbc95;
  border: none;
  color: #ffffff;
}

// #filterCohortsTransparent .p-chkbox .p-chkbox-box.p-state-active,
#filterCohortsTransparent .p-chkbox .p-chkbox-box {
  background-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
  border: none;
}

.small_pic {
  width: 50px;
  height: 50px;
}

.regular-columns {
  width: 5em;
}

.narrow-column {
  width: 2em;
}

app-push-cohorts {
  $root: &;

  & p-panel{
    & .p-panel-content{
      height: 30em;
    }


  }

  & #pushCohortsListBox{
    & .p-listbox-header .p-listbox-filter-container{
      width: 100%;
    }
  }

  & #mainPanel.noCohortSelected{
    & .p-panel-content {
        display: flex
      }
    }

  & app-cohort-push-section-view{
    & app-push-cohorts-create-or-edit{
      & p-dropdown.pushInput .p-dropdown, p-calendar .p-calendar input{
        width:16em;
      }
    }
  }

}

app-introductory {
  $root: &;

  & .button-list {
    display: flex;
    justify-content: flex-start;

    @include respond(md) {
      justify-content: center;
    }

    @include respond(xs) {
      flex-direction: column;
    }
  }

  button {
    width: 13rem;
    border-radius: $br-radius-1;

    @include respond(xs) {
      width: 100%;
    }

    &:first-of-type {
      margin-right: 1rem;

      @include respond(xs) {
        margin-bottom: 1rem;
      }
    }
  }

  p-dialog {
    .form-group {
      button {
        width: 100%;
        border-radius: $br-radius-1;
      }
    }
  }

  & .introductory {
    &__row {
      &-box {
        @include respond(md) {
          text-align: center;
        }
      }
    }

    &__button {
      border-radius: $br-radius-1;
      margin: 1rem 0;

      @include respond(md) {
        margin-bottom: 2rem;
      }

      @include respond(xs) {
        margin-bottom: 0;
        width: 100%;
      }

      & .p-button-icon-left {
        right: 65%;
      }

      &:last-of-type {
        @include respond(xs) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  & .fees_top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &--para {
      margin-right: 1rem;
      display: flex;
      align-items: baseline;
      font-size: 15px;

      & p {
        margin-left: 3px;
        line-height: 0;
        font-weight: 600;
      }
    }
  }



  & .p-spinner .p-spinner-button.p-spinner-up,
  .p-spinner .p-spinner-button.p-spinner-down {
    display: none;
  }

  & .p-spinner .p-spinner-input {
    background: none;
    color: white;
    height: 38px;
  }

  & p-spinner {
    & .p-corner-all, .p-corner-all {
      width: 100%;
    }
  }

  & .add_dialog {
    & p-dialog > .p-dialog {
      width: 47rem;
    }

    & .box {
      display: flex;

      &--inner:nth-child(1), &--inner:nth-child(2) {
        margin-right: 2rem;
      }
    }

    & .btn-box {
      text-align: right;

      & .submit {
        width: 35%;
        margin-right: 0;
      }
    }
  }
}

@mixin respond($breakpoint) {

  // Small devices (Small Phones, 375px and less) */
  @if $breakpoint==iphone-5 {
    @media only screen and (max-width: 22.5em) {
      @content;
    }
  }

  // Small devices (Small Phones, 375px and less) */
  @if $breakpoint==xxs {
    @media only screen and (max-width: 23.4375em) {
      @content;
    }
  }

  // Small devices (Medium Phones, 576px and less) */
  @if $breakpoint==xs {
    @media only screen and (max-width: 36.125em) {
      @content;
    }
  }

  // Small devices (landscape Phones, 768px and less) */
  @if $breakpoint==sm {
    @media only screen and (max-width: 48em) {
      @content;
    }
  }

  // Medium devices (Ipad, 992px and less) */
  @if $breakpoint==md {
    @media only screen and (max-width: 62em) {
      @content;
    }
  }

  // Large devices (Ipad Pro, 1024px and less) */
  @if $breakpoint==lg {
    @media only screen and (max-width: 64em) {
      @content;
    }
  }

   //  ( 1200 and less) */
   @if $breakpoint==xl {
    @media only screen and (max-width: 75em) {
      @content; //screen is <= 1200px / 16px = 75em
    }
  }

  //  ( 1280 and less) */
  @if $breakpoint==xxl {
    @media only screen and (max-width: 80em) {
      @content; //screen is <= 1280px / 16px = 80em
    }
  }

  // Large devices (Laptops,   1440px) */
  @if $breakpoint==laptops {
    @media only screen and (max-width: 96.25em) {
      @content;
    }
  }
}

app-classroom {
  $root: &;

  & p-table {
    & input {
      width: 18rem;

      @include respond(md) {
        width: 100%;
      }
    }
  }

  .classrooms {
    &__filter-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include respond(md) {
        flex-direction: column;
      }
    }

    &__filter-wrapper {
      display: flex;
      align-items: flex-start;

      @include respond(md) {
        flex-direction: column;
        width: 100%;
      }
    }

    &__submit-btn {
      width: 12rem;
      border-radius: $br-radius-1;

      @include respond(xs) {
        width: 100%;
      }
    }

    &__color-input {
      width: 12rem;

      @include respond(xs) {
        width: 100%;
      }
    }

    &__table-colorPicker > div{
      width: 100%;
      margin-top: 5px;
    }

    &__table-input {
      width: 100%;
    }

    &__table-dropdown > div {
      min-width: unset;
      width: 100%;
    }
  }

  app-institution-dropdown {
    @include respond(md) {
      width: 100%;
      margin-left: -0.5%;
    }
  }
}

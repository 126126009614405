app-skills {
  $root: &;

  & .skills {
    &__submit-btn {
      border-radius: $br-radius-1;
      width: 100%;
    }

    &__filters {
      &-row {
        display: flex;
        justify-content: space-between;

        @include respond(md) {
          flex-direction: column;
        }
      }

      &-wrapper {
        display: flex;
        align-items: flex-start;

        @include respond(md) {
          flex-direction: column;
        }

        p-dropdown {
          @include respond(md) {
            width: 100%;
            text-align: left;
          }
        }

        & input {
          padding: 0.55em 0.429em;
        }
      }
    }

    &__colgroup {
      & col {
        width: 80px;

        @include respond(laptops) {
          width: 180px;
        }

        &:first-of-type {
          @include respond(md) {
            width: 90px;
          }
        }
      }

      & .table-col-5 {
        width: 100px;

        @include respond(laptops) {
          width: 205px;
        }
      }
    }
  }
  }

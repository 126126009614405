app-broadcasts {
  $root: &;

  & .p-panel {
    & .p-panel-content {
      //this is what enables the /n as a new line
      white-space: pre-line;
    }
  }

  & .high_priority {

    & .p-panel-titlebar {
      font-weight: bold;
      font-size: larger;

    }
  }
}

//Light Mode
body[class*="color-scheme-light"] {
  $root: &;

  & app-broadcasts {
    & .high_priority {
      & .p-panel {
        & .p-panel-header {
          background-color: #545e72;
          color: white;
          border: 1px solid #545e72;
        }
      }
    }
  }
}


//Dark Mode
body[class*="color-scheme-dark"] {
  $root: &;

  & app-broadcasts {
    & .high_priority {
      & .p-panel {
        & .p-panel-header {
          color: white;
          background-color: #188ae2;
          border: 1px solid #188ae2;
        }

      }
    }
  }
}

app-courses-schedule {

  #courses_dropdown>div>div.p-dropdown-label-container {
    max-width: 14em;
  }

  $root: &;

  input, select,.p-dropdown, .p-calendar, .p-multiselect {
    width: 100%;
  }

  .courses-schedule {
    &__row {
      display: flex;
      justify-content: end;
      align-items: flex-end;

      @include respond(xs) {
        flex-wrap: wrap;
      }

      &:first-of-type {

        @include respond(lg) {
          margin-bottom: 0;
        }

      }

      &:last-of-type {
        margin-bottom: 5rem;

        @include respond(xs) {
          margin-bottom: 3rem;
        }
      }

      p-calendar {
        & input {
          width: 100%;
          height: 38px;
        }
      }

      app-institution-dropdown p-dropdown {
        margin-right: 1rem;

        @include respond(xs) {
          margin-left: 0 !important;
        }

        &>* {
          width: 100%;
        }
      }

      &-table {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond(xs) {
          flex-wrap: wrap;
        }
      }
    }

    &-btn {
      width: 15rem;
      border-radius: $br-radius-1;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(lg) {
        margin-bottom: 2rem;
      }

      @include respond(xs) {
        width: 100%;
      }

      &:first-of-type {
        margin-right: 1rem;

        @include respond(xs) {
          margin-right: 0;
        }
      }

      & span {
        &:first-of-type {
          position: unset;
          top: unset;
          left: unset;
          margin-top: unset;
        }
      }
    }

    &__select {
      width: fit-content;
      background-color: $bg-color-1;

      @include respond(xs) {
        width: 50%;
        margin-top: 1.5rem;
      }
    }

    &__colgroup {
      & col {

        @include respond(md) {
          width: 150px;
        }
      }
    }
  }

  & .p-button.p-button-text-icon-left .p-button-text {
    padding: 0.429em 1em 0.429em 0.5em;
  }

  & .p-tabview.p-tabview-top .p-tabview-nav li {
    width: 13rem;
    text-align: center;

    @include respond(md) {
      width: 9rem;
    }

    @include respond(xs) {
      width: 49%;
    }

    & span {
      @include respond(xs) {
        font-size: 14px;
      }
    }
  }
}

body[class*="color-scheme-dark"] {
  $root: &;

  app-courses-schedule {
    .courses-schedule {
      &__select {
        background-color: #3b4452;
      }
    }
  }
}

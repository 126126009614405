app-cohorts-send-contracts {
  $root: &;

  & .senior_fees {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5%;

    & p {
      margin-left: 0.5rem;
    }
  }
}

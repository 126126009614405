app-instructors {
  $root: &;

  & .instructors {
    &__row {
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 3rem;

      @include respond(lg) {
        justify-content: flex-start;
      }

      @include respond(md) {
        justify-content: center;
        margin-bottom: 0;
      }

      & .filter-btn {
        flex: 0 0 14%;

        @include respond(laptops) {
          flex: 0 0 17%;
        }

        @include respond(lg) {
          flex: 0 0 22%;
        }

        @include respond(md) {
          flex: 0 0 100%;
        }

        & i {
          width: unset;
        }
      }

      &>* {
        flex: 0 0 18%;
        margin-right: 1rem;
        margin-top: 1rem;

        @include respond(laptops) {
          flex: 0 0 23%;
        }

        @include respond(lg) {
          margin-bottom: 1rem;
          flex: 0 0 30%;
        }

        @include respond(md) {
          flex: 0 0 100%;
        }

        @include respond(xs) {
          flex: 0 0 100%;
          margin-right: 0;
        }

        &>* {
          width: 100%;
        }
      }

      app-institution-dropdown p-dropdown {
        &>* {
          width: 100%;
        }
      }

      &-buttons {
        display: flex;
        justify-content: space-between;

        @include respond(md) {
          margin-top: 5rem;
        }

        @include respond(xs) {
          flex-direction: column;
          margin-top: 0;
        }

        & .btn {
          width: 12rem;

          @include respond(xs) {
            width: 100%;
            margin-bottom: 0;
          }

          &:first-of-type {
            margin-right: 1rem;

            @include respond(xs) {
              margin: 1.5rem 0 1.5rem 0;
            }
          }

          &:last-of-type {
            @include respond(xs) {
              margin-bottom: 4rem;
            }

            & i {
              margin-right: 6px;
            }
          }
        }
      }
    }

    &__btns {
      @include respond(md) {
        display: flex;
      }

      @include respond(xs) {
        display: flex;
        flex-direction: column;
      }
    }

    &-filter-input {
      height: 38px;

      @include respond(md) {
        margin-right: 1rem;
        margin-bottom: 0;
        margin-top: 9px;
      }

      @include respond(xs) {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    &__colgroup {
      & col {
        width: 250px;

        &:nth-of-type(1),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:last-of-type {
          width: 160px;
        }

        &:nth-of-type(9) {
          width: 169px;
        }
      }
    }

    &__th {
      width: 6em
    }
  }


  p-dropdown {
    & .p-dropdown .p-dropdown-label {
      text-align: left;
    }
  }

  p-multiselect {
    .p-multiselect .p-multiselect-label {
      padding-right: 0;
      text-align: left;
    }
  }

  .mx-1 {
    @include respond(xs) {
      margin-left: 0 !important;
    }
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    @include respond(xs) {
      height: 2em;
      min-width: 1.4em;
    }
  }

  & app-fields-badges-template {
    margin-left: -7px;
  }
}

///Instructor View PAge
app-instructors-area {
  .instructor-view__zoom-link {
    word-break: break-all;
  }
}

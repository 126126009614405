app-instructors-area {
  $root: &;

  & .instructor-table {

    & th>td {
      word-wrap: break-word;
    }

  }

  & .inst-area {
    &__table-multiselect {
      &>div {
        width: 100%;
      }

      & .p-multiselect .p-multiselect-label-container {
        padding-right: 0;
      }
    }

    &__table-dropdown {
      &>div {
        min-width: unset;
        width: 100%;
        margin-top: 8px;
      }
    }
  }

  & .callOut {
    &--link:hover {
      text-decoration: underline;
    }

    &--textarea {
      width: 100%;
      min-height: 8rem;
      margin-top: 1rem;
    }

    &--btn {
      float: right;
      width: 25%;
    }

    &--checkbox {
      margin: 1rem 0;
    }
  }

  & .availability-dialog {


    & .availability-wrapper {
      height: 450px;
    }

    p-calendar span,
    p-calendar input {
      width: 100%;
    }

    // p-dialog .p-dialog-content.p-widget-content {
    //   min-height: 600px;
    // }

    p-dialog .pb-2-5 {
      padding-top: 2.25rem !important;
    }

    & .add-unavailability-button {
      display: flex;
      flex-direction: row-reverse;

      & button{
        width: 24em;
      }
    }
  }

  & .delete-dialog {

    p-dialog .p-dialog-content.p-widget-content {
      min-height: 100px;
    }
  }

  .min-h-500px {
    min-height: 500px;
  }

  & .switch-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    & .switch-view {
      display: flex;
      padding: 1.5rem 0 0rem 1.5rem;

      & p-inputSwitch {
        margin: 0 1rem;
      }

      & p {
        color: #414d5f;
        font-weight: 500;
      }
    }
  }


  app-extra-hour-requests{
    p-calendar span,
    p-dropdown,
    p-dropdown>div,
    p-calendar input {
      width: 100%;
    }

    & .row{
      margin-bottom: 1em;
    }
  }


  app-instructor-table {
    & table {

      & p-dropdown {
        & .p-dropdown.p-component {
          min-width: unset;
        }

        & .p-dropdown .p-dropdown-trigger {
          width: 1.657rem;
        }
      }
      & th {
        word-wrap: break-word;
      }
    }
    & .colgroup {
      & col {
        @include respond(laptops) {
          width: 150px;
        }

      }
    }

    & .table {
      &--filters {
        text-align: left
      }

      &--input {
        width: auto
      }

      &--dropdown {
        &>div {
          min-width: 100%;
        }
      }
    }

    & .approved {
      color: #27a55f;
    }

    & .rejected {
      color: #f34943;
    }
  }

}

app-scheduled-lessons {
  & select {
    @include respond(lg) {
      margin-top: 2rem;
    }

    @include respond(md) {
      margin-top: 3rem;
    }
  }

  & p-calendar .p-calendar {
    @include respond(md) {
      margin-top: 1rem;
    }
  }

  & .agreement-dialog {
    & p-dialog > div {
      & > div{
        width: 40rem;
        text-align: center;
      }

      & .p-dialog-header{
        display: unset;
      }

      & a {
        margin: 1rem 0;
      }
    }
  }
}

app-modules-schedule{
  $root: &;

  & .filterInput{
    width: 100%;
  }

  & input.filterInput, .p-calendar input{
    height: 38px;
  }

  .filterInputWrapper{
    display: flex;
  }

  input, select,.p-dropdown, .p-calendar, .p-multiselect {
    width: 100%;
  }

  .custom-select{
    width:fit-content;
    float: right;
  }

  .filterActionRow{
    display: flex;
    justify-content: flex-end;

    & button {
      width: 15rem;
      border-radius: $br-radius-1;
      display: flex;
      justify-content: center;
      align-items: center;

      & span:first-of-type{
        position: unset;
        top: unset;
        left: unset;
        margin-top: unset;
      }
    }
  }

}

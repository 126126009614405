  app-error-from-server {
    $root: &;
  & .error-wrapper {
    display:flex;

    & .container {
      display: flex;
      height: 100%;
      width: 100%;

      & .row {
        justify-content: center;
        align-self: center;
        margin:auto;
      }
    }
  }
  }


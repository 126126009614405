body[class*="color-scheme-light"] {
  app-review-send {
    .details-wrapper > .overview-card {
      background-color: #F4F3F4;
    }

    .fee-table-wrapper {
      & tr:nth-child(odd) {
        background-color: #F4F3F4 !important;
      }
    }

    .fee-table-header,
    .fee-table-header th {
      color: #495260 !important;
    }
  }
}

body[class*="color-scheme-dark"] {
  app-review-send {
    .details-wrapper > .overview-card {
      background-color: #495260;
    }
  }
}

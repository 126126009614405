app-dates-overview-container {
  $root: &;

  .trHeight {
    height: 5em;
  }

  & .page-title {
    text-align: center;
  }

  & app-dates-overview {
    $root: &;

    p-spinner {
      input {
        height: 38px;
      }
    }

    .p-grid.p-grid-pad > .p-grid-row > div {
      padding: 0.25em 0;
    }

    & .p-grid-col-2 {
      width: 17%;
      margin-right: 1rem;
    }

    & .p-grid-col-5 {
      margin-right: 1rem;
    }

    & .p-grid-col-10 {
      width: 83%;
    }

    & .btn {
      width: 12rem;

      @include respond(xs) {
        width: 100%;
        margin-bottom: 2rem;
      }

      &:first-of-type {
        margin-right: 1rem;

        @include respond(xs) {
          margin-right: 0;
        }
      }
    }

    .dates-overview {
      &-main {
        min-height: 400px;
      }

      &-fluid {
        margin: 10px 0px;
      }

      &-table {
        width: 100%;

        &--link {
          margin-right: 1em;
        }

        .p-datatable .p-datatable-tbody > tr > td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 5.5em;
        }
      }

      &__paragraph {
        margin-top: 1.5rem;
        width: 100%;
        font-size: 18px;
        text-align: center;
      }

      &__wrapper {
        display: flex;
        align-self: start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: 0 1.5rem;

        @include respond(xxl) {
          justify-content: flex-start;
          padding: 0 0.5rem;
        }
      }

      &__wrap_middle {
        justify-content: center;

        p-multiSelect {
          margin-left: 1em;
          margin-right: 1em;
        }
      }

      &__row-selector {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0 0.5rem 0;

        @include respond(xs) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      &__colgroup {
        & col {
          width: 10em;
        }
      }
    }

    & .card-body {
      @include respond(xs) {
        padding: 0 0.5rem;
      }
    }

    p-multiSelect,
    p-dropdown,
    p-calendar,
    app-institution-multi-select {
      flex: 0 0 16%;

      @include respond(xxl) {
        flex: 0 0 30%;
        margin-right: 1rem;
        margin-top: 1rem;
      }

      @include respond(xs) {
        flex: 0 0 100%;
      }
    }

    .p-multiselect .p-multiselect-label-container {
      @include respond(laptops) {
        padding-right: 0;
      }
    }

    .dates-overview-multiselect {
      text-align: left;
      margin-right: 1rem;
      width: 100%;
    }

    #dateRange {
      & span {
        width: 100%;
      }

      .p-calendar input {
        padding: 0.53em 0.429em;
        width: 100%;
        margin-right: 1rem;

        &::placeholder {
          color: #000;
        }
      }
    }

    .dates-overview-select {
      width: fit-content;

      @include respond(xs) {
        width: 100%;
      }
    }

    & .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev {
      @include respond(xs) {
        min-width: 1.286em;
      }
    }

    & th label {
      width: 6rem;
      height: 3rem;
    }
  }
}

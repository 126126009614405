app-cohorts {
  $root: &;

  & .edit_icons {
    & a {
      color: #333333;
    }
  }

  & .add-module-dialog,
  .replan-cohort-dialog,
  .edit-basic-cohort-info {
    .p-dialog .p-dialog-content {
      overflow: unset;
    }
  }

  & app-attendance {
      & .attendanceLogsDialog {
        & p-dialog {
          & > div{
            width: 70rem;

            & .p-dialog-header {
              padding-left: 2.6rem
            }
          }
        }
      }
  }
}

app-agreement-popup {
  & .popup_agreement_is_opend{
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    & span{
      color: #333333;
      font-weight: 400 !important;

    }
  }
}
//Dark Mode
body[class*="color-scheme-dark"] {
  app-cohorts {
    $root: &;

    & .edit_icons {
      & a {
        color: #ced4da;
      }
    }
  }

  app-agreement-popup {
    & .popup_agreement_is_opend{
      background-color: #323a46;
      border: 1px solid #ffffff1a;
      & span{
        color: #ffffff;
        font-weight: 400 !important;
      }
    }
  }
}

.attendance-icon{
  transform: scale(1.25);
  cursor: pointer;
}

app-program-managers {
  $root: &;

  & .program_multiselect > div {
    width: 100%;
  }

  & app-field-multi-select{
   & p-multiselect {
     & .p-multiselect {
       width: 100%;
     }
    }
  }
}
   .dropdown_slug {
      font-size:14px;
      float:right;
      margin-top:4px
    }

   .program_multiselect {
     width: 100%;
   }




app-cohorts {
  $root: &;

  & .manage-cohorts {
    &__filters {
      margin-top: 2rem;
    }

    &__zoom-url {
      word-break: break-all;

      & a:hover {
        text-decoration: underline;
      }
    }
  }

  .zoomUrl-popup {
    &-container {
      width: 21rem;
      //height: 10rem;

      & input {
        width: 100%;
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      margin-top: 2rem
    }

    &-loading{
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      flex-direction: column;

      div{
        text-align: center;

        i{
          font-size: 3em;
        }
      }
    }
  }

  // .p-multiselect-panel .p-multiselect-header{

  // }

  // .p-multiselect-panel .p-multiselect-filter-container{
  //   float: right;
  //   width: 90%;
  // }

  // .p-multiselect-panel  .p-multiselect-close {
  //   display: none;
  // }

}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) {
  display: inline-block;
  width: 100%;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .p-multiselect-close{
  display: none;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .p-multiselect-filter-container{
  float: right;
  width: 90%;
}


.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .close-instructor-multiselect{
  float: right;
}

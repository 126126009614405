app-cohort-audit {
  $root: &;

  .overflow-cohorts{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 12rem;
  }


  & .header-title {
    text-align: center;
    padding-top: 1rem;
  }

  & .row {
    align-items: center;
    justify-content: center;

    & p-dropdown>div {
      width: 16rem;
    }
  }

  & .upper {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .cohort_dropdown>div {
      width: 15rem;
      margin-bottom: 1rem;
    }


  }

  & .down {

    & .cohort-box {
      text-align: center;

      & p>span {
        font-weight: 600;
        padding-right: 5px;
      }
    }

    & .row {
      margin-bottom: 3rem;
    }

    & .table-wrapper {
      margin-bottom: 5rem;

      &:last-of-type {
        margin-bottom: 3rem;
      }

      & p {
        margin-bottom: 4px !important;
      }

      &--head {
        display: flex;
        align-items: baseline;

        & .label {
          padding-right: 1rem;
          padding-left: 3px;
          font-weight: 600;
          font-size: 20px;
        }
      }

      & .center {
        text-align: center;
      }
    }
  }
}

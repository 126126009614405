app-students {
  $root: &;

  & .custom-spinner {
    font-size: 5em
  }

  & .centerInLarge {
    text-align: center;
  }

  & .row--box {
    @include respond(laptops) {
      padding-bottom: 1rem;
    }

    @include respond(xs) {
      width: 100%;
      text-align: center;
      padding-right: 5%;
    }

    & button {
      @include respond(xs) {
        width: 79%;
      }
    }
  }

  & .students-main-title {
    text-align: center;
  }

  .p-fileupload .p-fileupload-content {
    padding: 0;
  }

  & .p-fileupload .p-fileupload-buttonbar .p-button {
    width: 15rem;
    border-radius: $br-radius-1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0;
    float: left;

    @include respond(md) {
      width: 12rem;
    }

    @include respond(xs) {
      margin-bottom: 1rem;
      width: 15rem;
    }

    & span:first-of-type {
      left: unset;
      position: unset;
      top: unset;
      margin-top: unset;
    }

    & .p-button-text {
      padding: 0.5rem 0.3rem;
    }

    span .p-button-text-icon-left {
      left: unset;
      position: unset;
      top: unset;
      margin-top: unset;
    }
  }

  & .p-widget {
    & p-multiselect {
      width: $filer-with;
      margin-right: 1rem;
      margin-bottom: 1rem;
      margin-left: -0.2rem;
    }

    & input {
      width: $filer-with;
      margin-right: 1rem;
      margin-bottom: 1rem;
      line-height: 24px;

      @include respond(md) {
        margin-right: 1rem;
      }

      @include respond(xs) {
        width: 17rem;
      }
    }

    & p-dropdown,
    p-multiselect {
      margin-right: 1rem;
      width: $filer-with;
      margin-left: 0;

      &>div {
        width: 100%;
        text-align: left;
      }

      @include respond (md) {
        width: 100%;
        margin-right: 0;
      }
    }

    & .p-dropdown.p-dropdown-clearable {
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include respond (md) {
        width: 100%;
      }
    }

    & .p-dropdown.p-dropdown-clearable .p-dropdown-label {
      width: 16.9rem;
      text-align: left;

      @include respond(laptops) {
        width: 17rem;
      }

      @include respond(md) {
        width: $filer-with;
      }

      @include respond(xs) {
        width: 17rem;
      }

      @include respond(xxs) {
        width: 16rem;
      }
    }
  }

  .p-fileupload .p-fileupload-buttonbar {
    @include respond(xs) {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  & .m-2 {
    margin: 0 1rem 1rem 0 !important;
  }

  & .filter-btn_wrapper {
    @include respond(md) {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .students {
    &__row {
      & .card-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;

        @include respond(md) {
          text-align: center;
        }

        @include respond(xs) {
          padding: 1rem 0;
        }
      }

      &--box {
        padding-bottom: 1%;
      }
    }

    &__card-box {
      & .spinner-box {
        display: flex;
        height: 400px;
      }
    }

    &-btn {
      width: 15rem;
      height: 2.4rem;
      border-radius: $br-radius-1;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(lg) {
        width: 16rem;
      }

      @include respond(md) {
        width: 50%;
        margin-bottom: 1rem;
      }

      @include respond(xs) {
        width: 100%;
      }

      .p-button-text-icon-left {
        left: unset;
        position: unset;
        top: unset;
        margin-top: unset;
      }
    }

    &__filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: self-start;

      @include respond(md) {
        justify-content: space-evenly;
      }

      & .students-btn {
        & .p-button-text {
          padding: 0;
        }
      }
    }

    &__wrapper {
      @include respond(md) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }

      & .custom-select {
        width: fit-content;
        float: right;

        @include respond(md) {
          width: 50%;
          margin-top: 1rem;
        }

        @include respond(xs) {
          width: 100%;
        }
      }
    }

    &__colgroup {
      & col {
        @include respond(laptops) {
          width: 15rem;
        }
      }

      & .last {
        @include respond(laptops) {
          width: 9rem;
        }
      }
    }
  }

  #students-btn-download {
    width: 15rem;
    height: 2.4rem;
    border-radius: $br-radius-1;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(lg) {
      width: 16rem;
    }

    @include respond(md) {
      width: 50%;
      margin-bottom: 1rem;
    }

    @include respond(xs) {
      width: 100%;
    }

    & .p-button-text {
      padding: 0 0.3rem;
    }
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    @include respond(xs) {
      height: 2em;
      min-width: 1.4em;
    }
  }

  ////All glass icons of filter

  & .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon,
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    margin-top: -0.5em;
    color: #007ad9;
  }

  & .importValidationDialog {
    &>div {
      width: 85%;
    }
  }

  & .exportStudentData {
    &>div {
      width: 28em;
      height: 300px;
    }
  }

  & .p-datatable-scrollable-header {
    width: 100%;
  }

  & .p-datatable-scrollable-wrapper {
    margin-top: 3%;
  }

}

// Dropdown appendTo body scrolling issue
.newEnrolment {
  // & p-dialog>div>.p-dialog-content.p-widget-content {
  //   height: 60vh;
  // }
  .p-dropdown {
    width: 100%;
  }
}

app-cohort-and-student-counts {
  colgroup col {
    @include respond(laptops) {
      width: 304px;
    }
  }

  & .student-input {
    &:first-of-type {
      margin-right: 1rem;

      @include respond(lg) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}


app-student {
  $root: &;

  .enrollment {
    &__row {
      display: flex;

      @include respond(lg) {
       flex-direction: column;
      }
    }

    &__box {
      &--1 {
        width: 50%;

        @include respond(laptops) {
          width: 60%;
        }

        @include respond(lg) {
          width: 100%;
        }
      }
    }
  }

  & .reEntry-btn {
    width: 7rem;
  }

  & .reEntry-sub {
    width: 5rem;
  }

  app-student-enrollments {
    & .create_popup_field {
      width: 100%;
    }
  }
}

//Add a new user dialog
app-default-popup {
  $root: &;

  & .p-widget input.genereted_sis {
    width: 30px;
    height: 26px;
  }
}

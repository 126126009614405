app-student-enrollments {
  $root: &;

  & .newEnrolment {
    & p-dialog > .p-dialog {
      width: 22rem;
    }
    .p-dialog-mask{
      width: 100%;
    }
  }

  & .sf-id {
    font-size: 13px;
  }

  & app-create-student-enrollment {
    color: red;
    & app-default-popup{
      color: green;
      & .sf-op-id {
        height: 2.2rem;
        border-radius: 4px;
        border: 1px solid grey;
        padding: 2px 5px;
      }
    }
  }
}

app-pay-roll-audit {
  $root: &;

  & .pay-roll-audit {
    &__dropdown-right {
      & > * {
        min-width: 48%;

        @include respond(laptops) {
          min-width: 70%;
        }
      }
    }
  }

  #payPeriod {
    .mx-2 {
      margin-left: 0 !important;
    }

    & > * {
      min-width: 12rem;
    }
  }

  p-fileupload {
    & > * {
      min-width: 12rem;
    }

  }
}

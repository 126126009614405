app-student-profile-career-services {
  $root: &;

  .summary {
    margin-left: 0;
  }

  & .validation-error {
    color: red;
    font-size: 14px;
  }
}
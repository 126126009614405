app-courses-and-modules {
  $root: &;

  & p-checkbox {

    & label{
      font-weight: 600 !important; 
    }
  }
}


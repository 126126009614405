app-find-intro-course{
    $root: &;

    & p-calendar {
        .p-inputtext{
            margin: 0;
        }

        span{
            width: 100%;
        }

        span .p-widget{
            width: 100%;
        }
    }

    .no-wrap{
        flex-wrap:nowrap;
    }

    & p-listbox{
        // .p-widget-content{
        //     width: 100%;
        // }

        li.p-listbox-item.p-corner-all.ng-star-inserted {
            padding: 1rem;
            border-bottom: 1px solid #434e5e;
        }
    }

    p-progressSpinner{
        .p-progress-spinner-svg{
            height: 2rem;
        }

        .p-progress-spinner{
            height: 1rem;
            margin-top: 0.25rem;
        }
    }
}
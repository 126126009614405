app-extended {
  $root: &;

  & .card-box {
    //text-align: center;

    & .btn {
      width: 14rem;

      @include respond(xs) {
        margin-top: 1rem;
      }
    }

    & p-dropdown {
      & > * {
        width: 20rem;
      }
    }
  }

  .extended {
    &__card-box {
      text-align: center;
    }

    &__titles {
      text-align: center;

      & button {
        margin-right: 1rem;
        border-radius: $br-radius-1;
      }
    }

  }

  .module_th {
    width:5em;
  }

  p-dropdown > div {
    width: 100%;
  }

  & .inputs_wrapper {
    margin-top: 1rem;

      & > * {
        margin-top: 1rem;
      }
  }

  & .p-spinner .p-spinner-button.p-spinner-up,
  .p-spinner .p-spinner-button.p-spinner-down {
    display: none;
  }

  & .p-spinner .p-spinner-input {
    background: none;
    color: white;
    height: 38px;
  }

  & p-spinner {
    & .p-corner-all, .p-corner-all {
      width: 100%;
    }
  }

  & .box {
    display: flex;

    &--inner:nth-child(1), &--inner:nth-child(2)  {
      margin-right: 2rem;
    }

  }

  & .add_dialog {
    & p-dialog > .p-dialog {
      width: 42rem;
    }

    & .submit {
      float: right;
      width: 32%;
    }
  }
}

app-manage-admissions {
  $root: &;

  & .turned_off {
    display: flex;
    margin-top: 1rem;

    & p-inputSwitch{
      margin: 0 0.6rem;
    }
  }
}

.nat-filters {
  $root: &;

  & .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0;
  }
}

/* Fixes font issues */
@import url('https://fonts.googleapis.com/css?family=Gothic+A1:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600&display=swap');

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary,
body .p-datatable .p-datatable-summary {
  padding: 0.571em 1em;
  text-align: center;
}

@media screen and (max-width: 1000px) {

  body .p-datatable.p-datatable-responsive .p-datatable-thead>tr>th,
  body .p-datatable.p-datatable-responsive .p-datatable-tfoot>tr>td {
    display: none !important;
  }

  body .p-datatable.p-datatable-responsive .p-datatable-tbody>tr>td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .p-datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
  }
}

.higherOpacityDisabled span>input {
  opacity: .8 !important;
}


.darkBackground {
  background-color: #2a303a !important;
}

.lightBackground {
  background-color: #363f4c !important;
}

app-cohort-list .p-overlaypanel:after,
app-cohort-list .p-overlaypanel:before {
  border: none !important;
}


app-student-profile-career-services .p-calendar>input,
app-student-profile-career-services .p-calendar,
app-advanced-student-search .p-calendar {
  width: 100%;
}

app-instructors-area .p-tabview .p-tabview-panels {
  padding: 0;
}

.autoWidthDropdown div {
  width: 100%;
  min-width: auto;
}

/* app-dates-overview #dateRange>span input {
  width: auto;
  min-width: 16em;
} */

app-permissions .p-listbox {
  width: auto;
}


app-holidays .fc-event-container {
  cursor: pointer;
}

.fullpageModal {
  width: 100vw;
  z-index: 1003;
  left: 0px;
  top: 0px;
  transform: none;
  opacity: 1;
  height: 100vh;
}
.p-multiselect-label-container{
  text-align: initial;
}

img {
  max-width: 250px;
  max-height: 250px;
}

.contact-information,
.basic-information {
  margin: 10px 0px;
}

.contact-information h4,
.basic-information h4 {
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 15px;
}

.contact-title {
  display: inline-block;
  padding-bottom: 15px;
  width: 170px;
  font-size: 16px;
}


a {
  cursor: pointer;
}






body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover,
body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: pointer;
}

body {
  overflow: inherit !important;
}


::-webkit-scrollbar-thumb {
  background-color: #6c757d;
  outline: 1px solid #dee2e6;
}

::-webkit-scrollbar-corner {
  background-color: #858e9c;
}

::-webkit-scrollbar {
  width: 1em;
}

body .p-disabled,
body .p-widget:disabled {
  cursor: default;
}


.p-datepicker {
  width: 20rem;
  padding: 5px;
}

.p-datepicker table td > span{
  width: 2rem;
  height: 2rem;
}

.bold span {
  font-weight: 700;
}


body .p-widget,
body {
  font-family: Barlow, sans-serif !important;
  font-size: 16px;
}


body .p-datatable .p-datatable-thead>tr>th,
body .p-datatable .p-datatable-thead>tr>th {
  padding: 0.571em 0.857em;
  font-weight: 700;
}

.p-colorpicker-preview {
  width: 100%;
}


app-scheduler .p-picklist .p-picklist-list {
  height: 8em;
}


app-scheduler .p-picklist .p-picklist-list-wrapper {
  width: 27em;
}


app-scheduler .p-picklist .p-picklist-buttons {
  height: inherit;
}


app-bulk-cohort-recreator .p-picklist .p-picklist-list {
  height: 20em;
  width: 36em;
}




.pointer {
  cursor: pointer;
}

#instructorTable>div>p-paginator,
#skillsTable>div>p-paginator {
  display: none;
}

#subjectsGrouped>div>div.p-dropdown-label-container {
  min-width: 20em;
}

app-scheduler .p-picklist .p-picklist-buttons button[icon='pi pi-angle-double-left'],
app-scheduler .p-picklist .p-picklist-buttons button[icon='pi pi-angle-double-up'],
app-scheduler .p-picklist .p-picklist-buttons button[icon='pi pi-angle-double-down'],
app-scheduler .p-picklist .p-picklist-buttons button[icon='pi pi-angle-double-right'] {
  display: none;
}

.p-fileupload {
  display: inline-block;
}

/* body>app-root>app-main>div>div>app-instructors>div.row>div.col-lg-12.col-xl-9>div>p-table>div>div.p-datatable-caption.p-widget-header.ng-star-inserted {
  min-height: 60px;
} */

.p-multiselect .p-multiselect-label-container {
  padding-right: 4em;
}

p-dropdown,
body .p-multiselect .p-multiselect-label {
  line-height: 24px;
}

body .p-dropdown,
.p-multiselect-label,
body .p-multiselect .p-multiselect-label,
body .p-inputtext {
  font-size: 14px;
  font-weight: normal;
}

.p-datatable-reorderablerow-handle {
  cursor: move;
}

body .p-fileupload .p-fileupload-buttonbar {
  padding-left: 0;
}

.p-button.p-button-secondary{
  color: #ced4da;
  background-color: #2a303a;
  border: none!important;
}

.p-button.p-button-secondary:enabled:hover{
  color: #fff;
  background-color: #2a303a;
  border: none!important;
}


body .p-fileupload .p-fileupload-buttonbar .p-button {
  margin-top: 7px;
  top: 0.6em;
}

/* #ui-tabpanel-0>div>p-fileupload>div>div.p-fileupload-buttonbar.p-widget-header.p-corner-top>p-button:nth-child(2)>button,
#ui-tabpanel-0>div>p-fileupload>div>div.p-fileupload-buttonbar.p-widget-header.p-corner-top>p-button:nth-child(3)>button {
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
} */

/* #ui-tabpanel-0>div>p-fileupload>div>div.p-fileupload-buttonbar.p-widget-header.p-corner-top>p-button:nth-child(3) {
  margin-left: 9px;

} */

/* #photo_upload>div>div.p-fileupload-buttonbar.p-widget-header.p-corner-top>span.p-fileupload-choose.p-button.p-widget.p-state-default.p-corner-all.p-button-text-icon-left {
  display: none;
} */

app-basic-information .p-fileupload-files {
  display: none;
}


.hidden {
  display: none;
}

.show {
  display: table-cell;
}

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary,
body .p-datatable .p-datatable-summary {
  padding: 0.571em 0em;
}

/*
td{
  overflow: auto;
} */

.overflow-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}


#endTime>span>div,
#startTime>span>div {
  width: auto;
  border: none;
}

.top-number-badge {
  position: absolute;
  top: -5px;
  margin-left: 6px;
}



app-candidates .p-fileupload-content {
  display: none;
}


@media (min-width: 992px) {
  .navigation-menu>li .submenu.megamenu>li {
    width: 220px;
  }
}

.unsticky-header #topnav,
.unsticky-header .navbar-custom {
  position: absolute;
}



#sumTable {
  text-align: center;
}


/* #skillsDialogForRecruitmentProcess>div>div.p-dialog-content.p-widget-content,
#skillsScoreDialogForRecruitmentProcess>div>div.p-dialog-content.p-widget-content {
  height: calc(100vh - 57px) !important;
} */



.instructorMultiSelect {
  width: 100%;
  text-align: center;
}

app-permissions .p-picklist .p-picklist-list {
  height: 21.5em;
  width: 25.5em;
}


::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #adb5bd !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #adb5bd !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #adb5bd !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #adb5bd !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #adb5bd !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #adb5bd !important;
}


body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: inherit;
}


.invalid-feedback {
  display: inherit !important;
}

.form-control.ng-valid[required].ng-dirty[required] {
  border-color: #28a745;
}


.form-control.ng-invalid[required].ng-dirty[required] {
  border-color: #dc3545;
}


p-calendar.fullWidthCalendar span {
  width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message{
  color: #ced4da;
}

.p-sidebar-right{
  width: 40em!important;
}

p-dropdown.primeDropdownBootstrap div,
p-multiselect.primeDropdownBootstrap div,
p-calendar.primeDropdownBootstrap span,
p-inputmask.primeDropdownBootstrap input {
  border: none !important;
  top: -5px;
  background: none !important;
  width: 100%;
}

p-calendar.primeDropdownBootstrap span input {
  border: none !important;
  width: 100%;
}

p-inputmask.primeDropdownBootstrap input {
  padding: 0;
}

p-dropdown>div>div.p-dropdown-label-container>label {
  border: none !important;
}

@media screen and (max-width: 1000px) {

  .fullWidthResponsive>div,
  input.fullWidthResponsive,
  .fullWidthResponsive>p-dropdown>div,
  select.fullWidthResponsive,
  button.fullWidthResponsive,
  p-calendar.fullWidthResponsive>span>input,
  p-calendar.fullWidthResponsive>span,
  .fullWidthResponsive>p-multiselect>div {
    width: 100% !important;
    float: initial !important;
    top: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

}

@media screen and (min-width: 1001px) {

  .centerInLarge {
    text-align: center;
  }

}



textarea {
  padding: 0.5em !important;
}


body .p-overlaypanel {
  top: inherit !important;
  left: inherit !important;
}

.p-paginator .p-paginator-right-content {
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  float: none;
  vertical-align: top;
  border-radius: 0;
  font-size: smaller;
  /* display: inline-block; */
}

body .overlayPanelNotes .p-overlaypanel {
  margin-left: -10em;
}



.customDropdownAlign{
  text-align: left;
  margin: 0 0.5em;
}


body .p-listbox .p-listbox-footer{
  border-top: inherit;
  background-color: inherit;
}
.ConfirBtn{
  background-color: #2a303a;
  padding: 3px;
  border-radius: 12px;
  size: 40px;
  margin: 8px;
  color: aliceblue;
}
.reasignEnroll{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
}
.reasignEnrollBtns{
  margin-top: 12px;
}
body .p-paginator {
  padding-top: 16px;
}
.nameLink{
  padding-left: 2em;
}
.ReassignedEnrollmentsAndDropDown{
  display: flex;
}
.reasignEnrollBtns2{
  margin-top: 38px;
  margin-left: 35%;
  margin-bottom: 12px;
  display: flex;
}
.dropDownEnrollment{
 display: flex;
 padding-top: 40px;
 justify-content: space-between;
}
.dropDownAndRadioBtn{
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  width: 55%;
}
.radioBtns{
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  margin-bottom: 30px;
}
.BtnRadio{
  align-self: center;
}
.EnrollmentInfo{
  padding-top: 8px;
}
.FlexDirectionRow{
  flex-direction: row;
}
.p-dropdown.p-disabled .p-dropdown-trigger, .p-dropdown.p-disabled .p-dropdown-label {
  cursor: default;
  text-align: left;
}
body .p-dropdown.p-dropdown-clearable .p-dropdown-label {
  text-align: left;
}

#attendanceListBox > div > div.p-listbox-list-wrapper > ul > li{
  border-bottom: 1px solid #ababab;
  padding: 1rem 0.2rem 1rem 0.2rem;
}

.color-scheme-dark .p-datatable-header {
  border: 1px solid #323a46;
  color: #98a6ad;
  background-color: #323a46
}

.p-paginator .p-paginator-right-content{
  width: 100%;
  text-align: center;
}

.p-checkbox-label{
  margin: 0 0 0 0.5em;
}

.p-dropdown:not(.p-paginator-rpp-options){
  min-width: 12.5em;
}

p-paginator > div > p-dropdown > div{
  min-width: 5.5em;
}

.p-button .p-button-icon-left{
  position: static;
  margin-top: 0;
}

.p-fileupload .p-fileupload-row > div{
  white-space: nowrap;
  width: 100%;
}

.p-datatable .p-datatable-footer{
  text-align: center;
  border: 1px solid #323a46;
  color: #98a6ad;
  background-color: #323a46;
  padding: 0.571em 0;
}
.p-datatable-header{
  text-align: center;
  padding: 0.571em 0;
}

.p-datepicker{
  min-width: 20rem!important;
}

.p-multiselect .p-multiselect-label-container{
  border: 1px solid #434e5e;
}

.p-datatable .p-datatable-header{
  padding: .571em 0;
}

.p-datatable .p-datatable-tbody > tr > .small_th{
  text-align: center;
}

p-chips {
  display: inline-block;
}
.p-paginator .p-dropdown .p-dropdown-label{
  color: #848484;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
  border: 1px solid #323a46;
  color: #98a6ad;
  background-color: #323a46;
}

.p-datatable .p-datatable-scrollable-footer, .p-datatable .p-datatable-scrollable-header{
  background-color: inherit;
}


.ui-column-title{
  display: none;
}


.p-picklist-list{
  height: 12.5em;
}

.p-picklist .p-picklist-header{
  background: unset;
  color: #ced4da;
  text-align: center;
  border: 1px solid #434e5e;
}

.p-picklist-buttons button, .p-picklist .p-picklist-buttons .p-button{
  width: 6em;
}


.p-panel .p-panel-header{
  border: 1px solid #3b4452;
  background-color: #394454;
  color: inherit;
}

.p-picklist-item:not(.cdk-drag-disabled) {
  cursor: move;
  z-index: 1130 !important;
}



.p-datatable-scrollable-header{
  width: calc(100% - 1em);
}

.p-datatable-frozen-view .p-datatable-scrollable-header{
  width: unset;
}

p-dialog#zoom-link-partial-failure-dialog .p-datatable-scrollable-wrapper ::-webkit-scrollbar-thumb{
  background-color: #FFFFFF;
}

app-instructor-callouts {
  $root: &;

  & p-tabView {
    & ul {
      & li {
        width: $tab-with;

        @include respond(xs) {
          width: 49%;
        }
      }
    }
  }

  & .callOuts-table {

    & td {
      overflow: hidden;
    }

    &__filter-wrapper {
      @include respond(xs) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      & * {
        @include respond(xs) {
          width: 100%;
        }
      }
    }

    & input {
      width: $input-with;

      @include respond(md) {
        width: $ipad-input-with;
      }

      @include respond(xs) {
        width: 100%;
      }
    }

    .program-filter {
      @include respond(xs) {
        margin-left: 0 !important;
      }
    }

    .cohort-filter {
      @include respond(xs) {
        margin-top: 1rem;
      }
    }

    &--td {
      padding: 0;
    }

    & .p-datatable-tbody>tr>td {
      text-align: center;
    }

    & select {
      @include respond(xs) {
        margin-bottom: 1rem;
      }
    }
  }

  .reason {
    padding: 1rem;
  }

  .p-dialog .p-dialog-header {
    padding: 1em 2em;
  }

  .show-reason {
    color: $info-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & .agreement-dialog {
    & p-dialog > div {
      text-align: center;

      & a {
        margin: 1rem 0;
      }
    }
  }
}

/*
Template Name: Greeva - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 2.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";
@import "./node_modules/bootstrap/scss/bootstrap";
//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
$theme: 'dark' !default;

@import "dark/darkmixin";
@import "light/lightmixin";

//  @include darkmixin;


.color-scheme-dark {
  background-color: #2a303a;
  color: white;
  @include darkmixin;
  @import "dark/components/form-wizard";
}



.color-scheme-light {
  $theme: 'light';
  background-color: #f5f6f8;
  color: #414d5f;

  @include lightmixin;
  @import "light/plugins/form-wizard";
}






// @import "bootstrap_light";

@import "./node_modules/bootstrap/scss/mixins.scss";
@import "custom/general";

app-instructors-management {
  $root: &;

  .content {
    display: flex;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .title-links {
    width: 139px;
  }

  & .number {
    width: 100%;
    padding-right: 10px;
    line-height: 50px;

    &:hover {
      text-decoration: underline;
    }

    &__url {
      overflow-wrap: anywhere;
    }
  }

  .credential-para {
    font-weight: 500;
  }

  .credential-li {
    list-style: none;
    margin-bottom: 1rem;

    & a {
      width: 85%;
      margin-bottom: 9px;

      & .credential_span {
        color: #000;
      }
    }
  }

  p-button button {
    width: 8rem;
    margin-top: 1rem;
  }

  .modalityPreferenceDialog {
    & .preference-dialog {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;

      p-dropdown {
        width: 70%;

        &>div {
          width: 100%;
        }
      }
    }
  }

  .error-message {
    height: 100px;
  }

  app-scheduled-lessons {

    & .noCallNoShow {
      &__title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }

      &__radioBbox {
        padding: 1.5rem 0 2.5rem 0;
        display: flex;
        justify-content: space-evenly;
      }

      &__btnBox {
        display: flex;
        justify-content: center;

        & button {
          width: 7rem;
        }
      }
    }

  }

  //University Approval
  & .univ_approval {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 1.5rem 0;

    &__box {
      display: flex;
      flex-direction: column;
      width: 47%;

      & input {
        padding: 3px 0;
      }
    }
  }

  & .univ_app_btn {
    margin: 1rem 0 1.5rem 0;
  }

  & .university_approval {
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
      display: flex;
      align-items: center;
      width: 50%;

      @include respond(md) {
        width: 100%;
      }

      & p {
        margin: 0.5rem 1rem 0.5rem 0;
      }

      & i {
        font-size: 20px;
        padding-right: 5px;
        cursor: pointer;
      }
    }

    &__icons {
      display: flex;
    }

    &__link {
      text-decoration: underline;
      margin: 1rem 0;
    }
  }

  .hire_date_calendar {
    width: 100%;

    & span {
      width: 100%;

      & input {
        width: 100%;
      }
    }
  }
}

body[class*="color-scheme-dark"] {
  $root: &;

  app-instructors-management {
    & .univ_approval {
      &__box {
        & input {
          background-color: unset;
          border: 1px solid #fff;
          color: #fff;
        }
      }
    }
  }
}

app-toggle-teaxtarea {
  $root: &;

  .text-wrapper {
    margin-left: 0;

    &__para {
      margin-left: 18px;
    }

    &__textarea {
      width: 90%;
      height: 10rem;
      border: 1px solid #a6a6a6;
    }

    & .btn-outline-info {
      width: 8rem;
    }

    & .btn-rounded {
      width: 5rem;
      float: left;
      margin-left: 18px;
    }

    &__text {
      font-size: 17px;
    }

    &--error {
      display: inline-block;
      margin: 1rem 0 1.5rem 0;
      color: red;
      width: 100%;
    }

    & textarea {
      width: 37%;
      height: 13rem
    }

    & button {
      width: 10rem;
      float: right;
    }
  }

  .pi-spinner {
    font-size: 5em;
  }
}
app-institutions {
  $root: &;

  & .institutions {
    &__submit-btn {
      width: 12rem;
      border-radius: $br-radius-1;

      @include respond(xs) {
        width: 100%;
      }
    }

    &__color-input {
      width: 12rem;

      @include respond(xs) {
        width: 100%;
      }
    }

    &__color-cellEditor {
      display: flex;
      flex-wrap: wrap;

      & input {
        width: 100%;
      }
    }

    &__table-colorPicker {
      width: 100%;
      margin-top: 5px;
    }

    &__city-dropdown > div{
        min-width: unset;
        width: 100%;
    }

    &__city-dropdown{
      .p-dropdown{
        min-width: unset;
      }
  }
  }
}

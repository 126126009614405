app-instructor-forecasting {
  $root: &;

  .page-title-box .page-title {
    &-h1 {
      text-align: center;
      font-size: $dsk-headline--1;
      font-weight: $fw-bold;
      color: $text-color-dark;
      padding: 1rem 0;
      margin-top: 0;
    }
  }

  .card-box .instructor-forecasting-box {
    @include respond(xs) {
      padding: 0.6rem;
    }
  }

  .instructor-forecasting-label-title {
    color: $text-color-dark;
    font-weight: $fw-medium;
    font-size: $dsk-article-headline-1;
  }

  .instructor-forecasting-box {
    input {
      padding: 0.429em 0.5rem;
      min-width: unset;
      width: 19rem;

      @include respond(laptops) {
        width: 15rem;
      }

      @include respond(xs) {
        width: 16rem;
      }
    }

    & .row {
      margin-bottom: 1rem !important;
    }

    & .p-dropdown {
      width: 19rem;

      @include respond(laptops) {
        width: 15rem;
      }

      @include respond(xs) {
        width: 16rem;
      }
    }
  }

  .instructor-forecasting-box:first-of-type {
    label {
      flex: 0 0 8%;

      @include respond(laptops) {
        flex: 0 0 12%;
      }

      @include respond(lg) {
        flex: 0 0 14%;
      }

      @include respond(md) {
        flex: 0 0 20%;
      }

      @include respond(xs) {
        flex: 0 0 100%;
      }
    }
  }

  .instructor-forecasting-box:nth-last-of-type(2) {
    & .instructor-forecasting-row {
      margin-left: 0;

      & .col-6 {
        @include respond(xs) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      & .col-6:last-of-type {
        @include respond(xl) {
          margin-top: 3rem;
        }

        & .row {
          display: flex;
          align-items: center;

          & p-inputswitch {
            margin-left: 0;
            display: flex;
          }
        }

        & .label {
          padding-left: 0 !important;
        }

        & label {
          flex: 0 0 26%;

          @include respond(laptops) {
            flex: 0 0 27%;
          }

          @include respond(xxl) {
            flex: 0 0 100%;
          }
        }

        & .instructor-forecasting-label-title {
          @include respond(laptops) {
            flex: 0 0 50%;
          }
        }

        & .inputSwitch {
          display: flex;

          @include respond (xs) {
            margin-top: 1rem;
            margin-bottom: 2rem;
          }
        }

        p-inputswitch {
          @include respond(lg) {
            padding-left: 2rem !important;
          }

          @include respond (xs) {
            padding-left: 0 !important;
          }
        }
      }
    }

    & .row .my-2 {
      display: flex;

      & label {
        flex: 0 0 19%;
        padding: 0.429em 0.5rem;

        @include respond(laptops) {
          flex: 0 0 29%;
        }

        @include respond(xl) {
          flex: 0 0 12%;
        }

        @include respond(lg) {
          flex: 0 0 14%;
        }

        @include respond(md) {
          flex: 0 0 20%;
        }
      }
    }

    p-dropdown {
      margin: 0 !important;
    }
  }

  .instructor-forecasting-box:last-of-type {
    @include respond(md) {
      text-align: center;
    }

    & .instructor-forecasting-btn-wrapper {
      @include respond(md) {
        display: flex;
        justify-content: center;
      }

      @include respond(xs) {
        flex-wrap: wrap;
      }
    }
  }

  .instructor-forecasting-calendar {
    padding-left: 0 !important;
  }

  & .row .my-2 .px-2 {
    padding-left: 0 !important;
  }

  & .instructor-forecasting-btn {
    width: 13rem;
    margin: 0.5rem 1rem 0.5rem 0 !important;

    @include respond(xs) {
      width: 100%;
    }
  }

  ///Table

  & p-tabview ul li {
    min-width: 7%;
    text-align: center;

    @include respond(xs) {
      width: 49%;
    }
  }
}


///Light Mode
body[class*="color-scheme-light"] {
  $root: &;
}

//Dark Mode
body[class*="color-scheme-dark"] {
  $root: &;

  & app-instructor-forecasting {
    & .page-title-box .page-title {
      &-h1 {
        color: $text-color-light;
      }
    }

    & .instructor-forecasting-label-title {
      color: $text-color-light;
    }
  }
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
  position: relative;
  color: white;
}

.card-box {
  padding: 1.5rem;
  margin-bottom: 24px;
  border-radius: 0.25rem;
}

.sticky-table {
  &.p-datatable .p-datatable-thead > tr > th {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0px;
    z-index: 1;
  }

  &.p-datatable-resizable > .p-datatable-wrapper {
    overflow-x: initial !important;
  }

  &.p-datatable-resizable .p-resizable-column {
    position: sticky !important;
  }

  @media screen and (max-width: 64em) {
    .p-datatable .p-datatable-thead > tr > th {
      top: 0px;
    }
  }
}

.multi_match_dropdown {
  text-align: left;
  top: 0;
}

.w-6-em {
  width: 6em;
}

.w-7-em {
  width: 7em;
}
.w-8-em {
  width: 8em;
}

.w-12-em {
  width: 12em;
}

/* for use this class the parent should be with position-relative */
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.display-grid {
  display: grid;
}

.newEnrolment {
  &.p-dialog-content .ReassignedEnrollmentsAndDropDown  {
    overflow-y: scroll !important;
  }
  app-skills-blueprint-match {
    .full-width > div {
      width: 100%;
    }

    .show-templates {
      color: #28a745;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.default-rem {
  width: 15rem;
}

.overflow-course{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 21em;
}

.white-space-initial{
  white-space: initial;
}

.global-col-filter input,
.global-col-filter .p-select,
.global-col-filter .p-multiselect,
.global-col-filter .p-dropdown {
  width: 100%;
}

.border-radios-32 {
  border-radius: $br-radius-1;
}
.border-radios-8 {
  border-radius: $br-radius-8;
}

.remove-resize {
  resize: none;
}

.bg-dark-grey {
  background-color: #495260;
}

.minus-m-025 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.small-spinner {
  font-size: 0.25rem;
  float: left;
}

.cohort-tag {
  background-color: #2e87df;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

.student-enrollment-tag {
  background-color: #5fbf24;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

.p-paginator-bottom .p-dropdown-label.p-inputtext{
  padding-right: 10px;
}
.p-paginator-bottom .p-paginator-right-content{
  color: white;
}

//Dark Mode
body[class*="color-scheme-dark"] {
  .bg-dark-grey {
    background-color: #495260;
  }
}
//Light Mode
body[class*="color-scheme-light"] {
  .bg-dark-grey {
    background-color: #efeeee;
  }
}
.ml-2h {
  margin-left: 0.75rem !important;
}

.ml-3h {
  margin-left: 1.25rem !important;
}

.p-confirm-dialog {
  width: 30em;
}

.p-confirm-dialog .p-dialog-footer {
  display: flex;
  flex-direction: row-reverse;
}

.btn-danger.p-button:enabled:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-success.p-button:enabled:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #ffffffaa;
}
.color-scheme-dark
  .p-tabview
  .p-tabview-nav
  li:not(.p-highlight)
  .p-tabview-nav-link {
  background-color: #323a46;
  border-color: #323a46;
}
.color-scheme-dark
  .p-tabview
  .p-tabview-nav
  li:not(.p-highlight)
  .p-tabview-nav-link:hover {
  background-color: #323a4688;
  border-color: #323a46;
}
.p-datatable .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-dialog-header .p-dialog-header-icons {
  position: absolute;
  right: 12px;
  top: 12px;
}

.p-dropdown-panel .p-dropdown-header{
  background-color: #2a303a;
  border: 1px solid #3b4452;
}

.color-scheme-dark .p-dropdown-panel{
  background-color: #3b4452;
}

.p-button .p-button-icon-left {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  height: 1em;
  left: 0.5em;
}

.p-datatable-scrollable-header-box{
  padding-right: 0 !important;
}

.p-dropdown-clear-icon{
  margin-right: 8px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.p-datepicker table td.p-datepicker-today > span{
  color: #323a46;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 10px;
}

.p-paginator-right-content{
  color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  border: 0px solid !important;
  box-shadow: unset !important;
}

.p-grid-row{
  display: flex;
}

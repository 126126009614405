app-extra-hours {
  $root: &;

  & .p-tabview.p-tabview-top .p-tabview-nav  li {
    width: 13rem;
    text-align: center;

    @include respond(xs) {
      width: 49%;
    }

    & span {
      @include respond(xs) {
        font-size: 14px;
      }
    }
  }

  & .p-button.p-button-text-icon-left .p-button-text {
    padding: 0.429em 1em 0.429em 1em;
  }

  & .p-dialog-content{
    input, select,.p-dropdown, .p-calendar, .p-multiselect {
      width: 100%;
    }
  }

  & .extra-hours {
    &-btn {
      border-radius: $br-radius-1;
      padding: 0;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;

      & span {
        &:first-of-type {
          position: unset;
          top: unset;
          left: unset;
          margin-top: unset;
          width: 5px;
        }

        &:last-of-type {
          padding: 0;
          width: 50%;
        }
      }
    }

    &__row {
      &-up {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 3rem;

        @include respond(md) {
          justify-content: center;
        }

        @include respond(xs) {
          margin-bottom: 1rem;
        }

        & > * {
          flex: 0 0 15%;
          margin-right: 1rem;

          @include respond(laptops) {
            flex: 0 0 23%;
            margin-bottom: 1rem;
          }

          @include respond(md) {
            flex: 0 0 43%;
          }

          @include respond(xs) {
            flex: 0 0 100%;
          }

          & > * {
            width: 100%;
          }

          .p-multiselect .p-multiselect-label-container {
            padding: 0;
            text-align: left;
          }
        }

        p-calendar {
          & input {
            width: 100%;
            height: 38px;
          }
        }
      }

      &-down {
        display: flex;
        justify-content: space-between;

        @include respond(xs) {
          flex-wrap: wrap;
        }

        & .btn {
          width: 16rem;

          @include respond(lg) {
            width: 13rem;
          }

          @include respond(xs) {
            width: 100%;
          }

          &:last-of-type{
            @include respond(xs) {
              margin-left: -2%;
            }
          }
        }

        & .custom-select {
          width: fit-content;

          @include respond(xs) {
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
    }

    &-pending-table {
      @include respond(md) {
        width: 190px;
      }
    }

    &__colgroup {
      & col {
        width: 250px;

        @include respond(xs) {
          width: 200px;
        }
      }
    }

    ///Pending
    &__pending {
      &--title {
        text-align: center;
      }
    }
  }

  .pending-table {
    & .extra-hours-pending-table {
      & col {
       @include respond(laptops) {
        width: 200px;
       }
      }
    }
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    @include respond(xs) {
      height: 2em;
      min-width: 1.4em;
    }
  }
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
  position: relative;
  color: white;
}

.card-box {
  padding: 1.5rem;
  margin-bottom: 24px;
  border-radius: 0.25rem;
}

.sticky-table.p-datatable .p-datatable-thead > tr > th {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0px;
  z-index: 1;
}
.sticky-table.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: initial !important;
}
.sticky-table.p-datatable-resizable .p-resizable-column {
  position: sticky !important;
}
@media screen and (max-width: 64em) {
  .sticky-table .p-datatable .p-datatable-thead > tr > th {
    top: 0px;
  }
}

.multi_match_dropdown {
  text-align: left;
  top: 0;
}

.w-6-em {
  width: 6em;
}

.w-7-em {
  width: 7em;
}

.w-8-em {
  width: 8em;
}

.w-12-em {
  width: 12em;
}

/* for use this class the parent should be with position-relative */
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.display-grid {
  display: grid;
}

.newEnrolment.p-dialog-content .ReassignedEnrollmentsAndDropDown {
  overflow-y: scroll !important;
}
.newEnrolment app-skills-blueprint-match .full-width > div {
  width: 100%;
}
.newEnrolment app-skills-blueprint-match .show-templates {
  color: #28a745;
  cursor: pointer;
}
.newEnrolment app-skills-blueprint-match .show-templates:hover {
  text-decoration: underline;
}

.default-rem {
  width: 15rem;
}

.overflow-course {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 21em;
}

.white-space-initial {
  white-space: initial;
}

.global-col-filter input,
.global-col-filter .p-select,
.global-col-filter .p-multiselect,
.global-col-filter .p-dropdown {
  width: 100%;
}

.border-radios-32 {
  border-radius: 32px;
}

.border-radios-8 {
  border-radius: 8px;
}

.remove-resize {
  resize: none;
}

.bg-dark-grey {
  background-color: #495260;
}

.minus-m-025 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.small-spinner {
  font-size: 0.25rem;
  float: left;
}

.cohort-tag {
  background-color: #2e87df;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

.student-enrollment-tag {
  background-color: #5fbf24;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

.p-paginator-bottom .p-dropdown-label.p-inputtext {
  padding-right: 10px;
}

.p-paginator-bottom .p-paginator-right-content {
  color: white;
}

body[class*=color-scheme-dark] .bg-dark-grey {
  background-color: #495260;
}

body[class*=color-scheme-light] .bg-dark-grey {
  background-color: #efeeee;
}

.ml-2h {
  margin-left: 0.75rem !important;
}

.ml-3h {
  margin-left: 1.25rem !important;
}

.p-confirm-dialog {
  width: 30em;
}

.p-confirm-dialog .p-dialog-footer {
  display: flex;
  flex-direction: row-reverse;
}

.btn-danger.p-button:enabled:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-success.p-button:enabled:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #ffffffaa;
}

.color-scheme-dark .p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link {
  background-color: #323a46;
  border-color: #323a46;
}

.color-scheme-dark .p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link:hover {
  background-color: #323a4688;
  border-color: #323a46;
}

.p-datatable .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-dialog-header .p-dialog-header-icons {
  position: absolute;
  right: 12px;
  top: 12px;
}

.p-dropdown-panel .p-dropdown-header {
  background-color: #2a303a;
  border: 1px solid #3b4452;
}

.color-scheme-dark .p-dropdown-panel {
  background-color: #3b4452;
}

.p-button .p-button-icon-left {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  height: 1em;
  left: 0.5em;
}

.p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}

.p-dropdown-clear-icon {
  margin-right: 8px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.p-datepicker table td.p-datepicker-today > span {
  color: #323a46;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 10px;
}

.p-paginator-right-content {
  color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  border: 0px solid !important;
  box-shadow: unset !important;
}

.p-grid-row {
  display: flex;
}

app-dates-overview-container .trHeight {
  height: 5em;
}
app-dates-overview-container .page-title {
  text-align: center;
}
app-dates-overview-container app-dates-overview p-spinner input {
  height: 38px;
}
app-dates-overview-container app-dates-overview .p-grid.p-grid-pad > .p-grid-row > div {
  padding: 0.25em 0;
}
app-dates-overview-container app-dates-overview .p-grid-col-2 {
  width: 17%;
  margin-right: 1rem;
}
app-dates-overview-container app-dates-overview .p-grid-col-5 {
  margin-right: 1rem;
}
app-dates-overview-container app-dates-overview .p-grid-col-10 {
  width: 83%;
}
app-dates-overview-container app-dates-overview .btn {
  width: 12rem;
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .btn {
    width: 100%;
    margin-bottom: 2rem;
  }
}
app-dates-overview-container app-dates-overview .btn:first-of-type {
  margin-right: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .btn:first-of-type {
    margin-right: 0;
  }
}
app-dates-overview-container app-dates-overview .dates-overview-main {
  min-height: 400px;
}
app-dates-overview-container app-dates-overview .dates-overview-fluid {
  margin: 10px 0px;
}
app-dates-overview-container app-dates-overview .dates-overview-table {
  width: 100%;
}
app-dates-overview-container app-dates-overview .dates-overview-table--link {
  margin-right: 1em;
}
app-dates-overview-container app-dates-overview .dates-overview-table .p-datatable .p-datatable-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 5.5em;
}
app-dates-overview-container app-dates-overview .dates-overview__paragraph {
  margin-top: 1.5rem;
  width: 100%;
  font-size: 18px;
  text-align: center;
}
app-dates-overview-container app-dates-overview .dates-overview__wrapper {
  display: flex;
  align-self: start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 0 1.5rem;
}
@media only screen and (max-width: 80em) {
  app-dates-overview-container app-dates-overview .dates-overview__wrapper {
    justify-content: flex-start;
    padding: 0 0.5rem;
  }
}
app-dates-overview-container app-dates-overview .dates-overview__wrap_middle {
  justify-content: center;
}
app-dates-overview-container app-dates-overview .dates-overview__wrap_middle p-multiSelect {
  margin-left: 1em;
  margin-right: 1em;
}
app-dates-overview-container app-dates-overview .dates-overview__row-selector {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0.5rem 0;
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .dates-overview__row-selector {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
app-dates-overview-container app-dates-overview .dates-overview__colgroup col {
  width: 10em;
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .card-body {
    padding: 0 0.5rem;
  }
}
app-dates-overview-container app-dates-overview p-multiSelect,
app-dates-overview-container app-dates-overview p-dropdown,
app-dates-overview-container app-dates-overview p-calendar,
app-dates-overview-container app-dates-overview app-institution-multi-select {
  flex: 0 0 16%;
}
@media only screen and (max-width: 80em) {
  app-dates-overview-container app-dates-overview p-multiSelect,
app-dates-overview-container app-dates-overview p-dropdown,
app-dates-overview-container app-dates-overview p-calendar,
app-dates-overview-container app-dates-overview app-institution-multi-select {
    flex: 0 0 30%;
    margin-right: 1rem;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview p-multiSelect,
app-dates-overview-container app-dates-overview p-dropdown,
app-dates-overview-container app-dates-overview p-calendar,
app-dates-overview-container app-dates-overview app-institution-multi-select {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 96.25em) {
  app-dates-overview-container app-dates-overview .p-multiselect .p-multiselect-label-container {
    padding-right: 0;
  }
}
app-dates-overview-container app-dates-overview .dates-overview-multiselect {
  text-align: left;
  margin-right: 1rem;
  width: 100%;
}
app-dates-overview-container app-dates-overview #dateRange span {
  width: 100%;
}
app-dates-overview-container app-dates-overview #dateRange .p-calendar input {
  padding: 0.53em 0.429em;
  width: 100%;
  margin-right: 1rem;
}
app-dates-overview-container app-dates-overview #dateRange .p-calendar input::placeholder {
  color: #000;
}
app-dates-overview-container app-dates-overview .dates-overview-select {
  width: fit-content;
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .dates-overview-select {
    width: 100%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-dates-overview-container app-dates-overview .p-paginator .p-paginator-first,
app-dates-overview-container app-dates-overview .p-paginator .p-paginator-prev {
    min-width: 1.286em;
  }
}
app-dates-overview-container app-dates-overview th label {
  width: 6rem;
  height: 3rem;
}

app-extra-hours .p-tabview.p-tabview-top .p-tabview-nav li {
  width: 13rem;
  text-align: center;
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .p-tabview.p-tabview-top .p-tabview-nav li {
    width: 49%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .p-tabview.p-tabview-top .p-tabview-nav li span {
    font-size: 14px;
  }
}
app-extra-hours .p-button.p-button-text-icon-left .p-button-text {
  padding: 0.429em 1em 0.429em 1em;
}
app-extra-hours .p-dialog-content input, app-extra-hours .p-dialog-content select, app-extra-hours .p-dialog-content .p-dropdown, app-extra-hours .p-dialog-content .p-calendar, app-extra-hours .p-dialog-content .p-multiselect {
  width: 100%;
}
app-extra-hours .extra-hours-btn {
  border-radius: 32px;
  padding: 0;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
}
app-extra-hours .extra-hours-btn span:first-of-type {
  position: unset;
  top: unset;
  left: unset;
  margin-top: unset;
  width: 5px;
}
app-extra-hours .extra-hours-btn span:last-of-type {
  padding: 0;
  width: 50%;
}
app-extra-hours .extra-hours__row-up {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 62em) {
  app-extra-hours .extra-hours__row-up {
    justify-content: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-up {
    margin-bottom: 1rem;
  }
}
app-extra-hours .extra-hours__row-up > * {
  flex: 0 0 15%;
  margin-right: 1rem;
}
@media only screen and (max-width: 96.25em) {
  app-extra-hours .extra-hours__row-up > * {
    flex: 0 0 23%;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 62em) {
  app-extra-hours .extra-hours__row-up > * {
    flex: 0 0 43%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-up > * {
    flex: 0 0 100%;
  }
}
app-extra-hours .extra-hours__row-up > * > * {
  width: 100%;
}
app-extra-hours .extra-hours__row-up > * .p-multiselect .p-multiselect-label-container {
  padding: 0;
  text-align: left;
}
app-extra-hours .extra-hours__row-up p-calendar input {
  width: 100%;
  height: 38px;
}
app-extra-hours .extra-hours__row-down {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-down {
    flex-wrap: wrap;
  }
}
app-extra-hours .extra-hours__row-down .btn {
  width: 16rem;
}
@media only screen and (max-width: 64em) {
  app-extra-hours .extra-hours__row-down .btn {
    width: 13rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-down .btn {
    width: 100%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-down .btn:last-of-type {
    margin-left: -2%;
  }
}
app-extra-hours .extra-hours__row-down .custom-select {
  width: fit-content;
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__row-down .custom-select {
    width: 100%;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 62em) {
  app-extra-hours .extra-hours-pending-table {
    width: 190px;
  }
}
app-extra-hours .extra-hours__colgroup col {
  width: 250px;
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .extra-hours__colgroup col {
    width: 200px;
  }
}
app-extra-hours .extra-hours__pending--title {
  text-align: center;
}
@media only screen and (max-width: 96.25em) {
  app-extra-hours .pending-table .extra-hours-pending-table col {
    width: 200px;
  }
}
@media only screen and (max-width: 36.125em) {
  app-extra-hours .p-paginator .p-paginator-pages .p-paginator-page {
    height: 2em;
    min-width: 1.4em;
  }
}

app-courses-schedule #courses_dropdown > div > div.p-dropdown-label-container {
  max-width: 14em;
}
app-courses-schedule input, app-courses-schedule select, app-courses-schedule .p-dropdown, app-courses-schedule .p-calendar, app-courses-schedule .p-multiselect {
  width: 100%;
}
app-courses-schedule .courses-schedule__row {
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule__row {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 64em) {
  app-courses-schedule .courses-schedule__row:first-of-type {
    margin-bottom: 0;
  }
}
app-courses-schedule .courses-schedule__row:last-of-type {
  margin-bottom: 5rem;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule__row:last-of-type {
    margin-bottom: 3rem;
  }
}
app-courses-schedule .courses-schedule__row p-calendar input {
  width: 100%;
  height: 38px;
}
app-courses-schedule .courses-schedule__row app-institution-dropdown p-dropdown {
  margin-right: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule__row app-institution-dropdown p-dropdown {
    margin-left: 0 !important;
  }
}
app-courses-schedule .courses-schedule__row app-institution-dropdown p-dropdown > * {
  width: 100%;
}
app-courses-schedule .courses-schedule__row-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule__row-table {
    flex-wrap: wrap;
  }
}
app-courses-schedule .courses-schedule-btn {
  width: 15rem;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 64em) {
  app-courses-schedule .courses-schedule-btn {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule-btn {
    width: 100%;
  }
}
app-courses-schedule .courses-schedule-btn:first-of-type {
  margin-right: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule-btn:first-of-type {
    margin-right: 0;
  }
}
app-courses-schedule .courses-schedule-btn span:first-of-type {
  position: unset;
  top: unset;
  left: unset;
  margin-top: unset;
}
app-courses-schedule .courses-schedule__select {
  width: fit-content;
  background-color: #f1f5f7;
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .courses-schedule__select {
    width: 50%;
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 62em) {
  app-courses-schedule .courses-schedule__colgroup col {
    width: 150px;
  }
}
app-courses-schedule .p-button.p-button-text-icon-left .p-button-text {
  padding: 0.429em 1em 0.429em 0.5em;
}
app-courses-schedule .p-tabview.p-tabview-top .p-tabview-nav li {
  width: 13rem;
  text-align: center;
}
@media only screen and (max-width: 62em) {
  app-courses-schedule .p-tabview.p-tabview-top .p-tabview-nav li {
    width: 9rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .p-tabview.p-tabview-top .p-tabview-nav li {
    width: 49%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-courses-schedule .p-tabview.p-tabview-top .p-tabview-nav li span {
    font-size: 14px;
  }
}

body[class*=color-scheme-dark] app-courses-schedule .courses-schedule__select {
  background-color: #3b4452;
}

app-modules-schedule .filterInput {
  width: 100%;
}
app-modules-schedule input.filterInput, app-modules-schedule .p-calendar input {
  height: 38px;
}
app-modules-schedule .filterInputWrapper {
  display: flex;
}
app-modules-schedule input, app-modules-schedule select, app-modules-schedule .p-dropdown, app-modules-schedule .p-calendar, app-modules-schedule .p-multiselect {
  width: 100%;
}
app-modules-schedule .custom-select {
  width: fit-content;
  float: right;
}
app-modules-schedule .filterActionRow {
  display: flex;
  justify-content: flex-end;
}
app-modules-schedule .filterActionRow button {
  width: 15rem;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
app-modules-schedule .filterActionRow button span:first-of-type {
  position: unset;
  top: unset;
  left: unset;
  margin-top: unset;
}

app-pay-roll-audit .pay-roll-audit__dropdown-right > * {
  min-width: 48%;
}
@media only screen and (max-width: 96.25em) {
  app-pay-roll-audit .pay-roll-audit__dropdown-right > * {
    min-width: 70%;
  }
}
app-pay-roll-audit #payPeriod .mx-2 {
  margin-left: 0 !important;
}
app-pay-roll-audit #payPeriod > * {
  min-width: 12rem;
}
app-pay-roll-audit p-fileupload > * {
  min-width: 12rem;
}

app-bug-reporting textarea {
  width: 100%;
  overflow: hidden;
}

app-classroom p-table input {
  width: 18rem;
}
@media only screen and (max-width: 62em) {
  app-classroom p-table input {
    width: 100%;
  }
}
app-classroom .classrooms__filter-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 62em) {
  app-classroom .classrooms__filter-row {
    flex-direction: column;
  }
}
app-classroom .classrooms__filter-wrapper {
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 62em) {
  app-classroom .classrooms__filter-wrapper {
    flex-direction: column;
    width: 100%;
  }
}
app-classroom .classrooms__submit-btn {
  width: 12rem;
  border-radius: 32px;
}
@media only screen and (max-width: 36.125em) {
  app-classroom .classrooms__submit-btn {
    width: 100%;
  }
}
app-classroom .classrooms__color-input {
  width: 12rem;
}
@media only screen and (max-width: 36.125em) {
  app-classroom .classrooms__color-input {
    width: 100%;
  }
}
app-classroom .classrooms__table-colorPicker > div {
  width: 100%;
  margin-top: 5px;
}
app-classroom .classrooms__table-input {
  width: 100%;
}
app-classroom .classrooms__table-dropdown > div {
  min-width: unset;
  width: 100%;
}
@media only screen and (max-width: 62em) {
  app-classroom app-institution-dropdown {
    width: 100%;
    margin-left: -0.5%;
  }
}

app-institutions .institutions__submit-btn {
  width: 12rem;
  border-radius: 32px;
}
@media only screen and (max-width: 36.125em) {
  app-institutions .institutions__submit-btn {
    width: 100%;
  }
}
app-institutions .institutions__color-input {
  width: 12rem;
}
@media only screen and (max-width: 36.125em) {
  app-institutions .institutions__color-input {
    width: 100%;
  }
}
app-institutions .institutions__color-cellEditor {
  display: flex;
  flex-wrap: wrap;
}
app-institutions .institutions__color-cellEditor input {
  width: 100%;
}
app-institutions .institutions__table-colorPicker {
  width: 100%;
  margin-top: 5px;
}
app-institutions .institutions__city-dropdown > div {
  min-width: unset;
  width: 100%;
}
app-institutions .institutions__city-dropdown .p-dropdown {
  min-width: unset;
}

app-skills .skills__submit-btn {
  border-radius: 32px;
  width: 100%;
}
app-skills .skills__filters-row {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 62em) {
  app-skills .skills__filters-row {
    flex-direction: column;
  }
}
app-skills .skills__filters-wrapper {
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 62em) {
  app-skills .skills__filters-wrapper {
    flex-direction: column;
  }
}
@media only screen and (max-width: 62em) {
  app-skills .skills__filters-wrapper p-dropdown {
    width: 100%;
    text-align: left;
  }
}
app-skills .skills__filters-wrapper input {
  padding: 0.55em 0.429em;
}
app-skills .skills__colgroup col {
  width: 80px;
}
@media only screen and (max-width: 96.25em) {
  app-skills .skills__colgroup col {
    width: 180px;
  }
}
@media only screen and (max-width: 62em) {
  app-skills .skills__colgroup col:first-of-type {
    width: 90px;
  }
}
app-skills .skills__colgroup .table-col-5 {
  width: 100px;
}
@media only screen and (max-width: 96.25em) {
  app-skills .skills__colgroup .table-col-5 {
    width: 205px;
  }
}

app-cohorts .manage-cohorts__filters {
  margin-top: 2rem;
}
app-cohorts .manage-cohorts__zoom-url {
  word-break: break-all;
}
app-cohorts .manage-cohorts__zoom-url a:hover {
  text-decoration: underline;
}
app-cohorts .zoomUrl-popup-container {
  width: 21rem;
}
app-cohorts .zoomUrl-popup-container input {
  width: 100%;
}
app-cohorts .zoomUrl-popup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
app-cohorts .zoomUrl-popup-loading {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
}
app-cohorts .zoomUrl-popup-loading div {
  text-align: center;
}
app-cohorts .zoomUrl-popup-loading div i {
  font-size: 3em;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) {
  display: inline-block;
  width: 100%;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .p-multiselect-close {
  display: none;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .p-multiselect-filter-container {
  float: right;
  width: 90%;
}

.p-multiselect-panel .p-multiselect-header:has(.show-inactive-checkbox) .close-instructor-multiselect {
  float: right;
}

app-introductory-to-extended-conversion .empty-cohort {
  text-align: center;
}
app-introductory-to-extended-conversion .card-box.upper .row {
  align-items: center;
  justify-content: center;
}
app-introductory-to-extended-conversion .card-box.upper .row .p-dropdown-panel {
  width: 20rem;
}
app-introductory-to-extended-conversion .card-box.upper .row .p-dropdown {
  min-width: unset;
  width: 20rem;
}
@media only screen and (max-width: 36.125em) {
  app-introductory-to-extended-conversion .card-box.upper .row .p-dropdown {
    width: 100%;
  }
}
app-introductory-to-extended-conversion .card-box.upper .card-box {
  margin-bottom: 0;
  text-align: center;
}
@media only screen and (max-width: 36.125em) {
  app-introductory-to-extended-conversion .card-box.upper .card-box {
    padding: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-introductory-to-extended-conversion .card-box.upper .card-box p-dropdown {
    width: 100%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-introductory-to-extended-conversion .card-box.upper .card-box p-dropdown > div {
    width: 100%;
  }
}
app-introductory-to-extended-conversion .filter-wrapper {
  display: flex;
  flex-wrap: wrap;
}
app-introductory-to-extended-conversion .filter-wrapper > * {
  width: 15rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-introductory-to-extended-conversion .filter-wrapper > * {
    width: 100%;
    margin-right: 0;
  }
}
app-introductory-to-extended-conversion .filter-wrapper .p-dropdown {
  width: 100%;
}
app-introductory-to-extended-conversion .filter-wrapper .convert-btn {
  border-radius: 32px;
}
app-introductory-to-extended-conversion .filter-wrapper .intro-dropdown {
  text-align: left;
}
app-introductory-to-extended-conversion tbody tr td:last-of-type {
  text-align: center;
}
app-introductory-to-extended-conversion .dialog-window .p-dialog {
  max-width: 1000px;
}
app-introductory-to-extended-conversion .dialog-window .p-dialog .p-dialog-header {
  padding: 0.5em 0.5em 0 0;
}
app-introductory-to-extended-conversion .dialog-window .p-dialog .p-dialog-header {
  padding: 0.5em 0.5em 0 0;
}
app-introductory-to-extended-conversion .dialog-window .row-box {
  margin: 1rem 0;
}
app-introductory-to-extended-conversion .dialog-window .row-box--inner {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
app-introductory-to-extended-conversion .dialog-window .row-box--inner p {
  margin-right: 3rem;
}
app-introductory-to-extended-conversion .dialog-window .row-box .sf-text {
  margin-top: 6px;
  font-size: 13px;
}
app-introductory-to-extended-conversion .dialog-window .dialog-btn {
  padding: 0 0 3px 0;
  float: right;
  width: 10rem;
}
app-introductory-to-extended-conversion .dialog-window .dialog-btn .p-button.p-button-text-only .p-button-text {
  padding: 0.4em 1em;
}
app-introductory-to-extended-conversion .dialog-window .p-dialog .p-dialog-content {
  border: none;
}
app-introductory-to-extended-conversion .dialog-window p-dropdown .p-dropdown {
  min-width: unset;
  width: 90%;
}
app-introductory-to-extended-conversion .dialog-window p-dropdown .p-dropdown:first-of-type {
  margin-right: 8px;
}
app-introductory-to-extended-conversion p-dropdown {
  text-align: left;
}
@media only screen and (max-width: 96.25em) {
  app-introductory-to-extended-conversion .colgroup col {
    width: 230px;
  }
}
app-introductory-to-extended-conversion .converted-intro-spinner {
  width: 20px;
  height: 20px;
}
app-introductory-to-extended-conversion .choose-cohort-dropdown {
  width: 14rem;
}
app-introductory-to-extended-conversion .p-dropdown-items-wrapper {
  max-height: 200px;
  overflow: auto;
}

app-career-coaches p-tabview .p-tabview.p-tabview-top .p-tabview-nav li {
  width: 11rem;
}
app-career-coaches p-tabview .p-tabview.p-tabview-top .p-tabview-nav li a {
  width: 100%;
  text-align: center;
}
app-career-coaches .careerCoaches__submit-btn {
  width: 100%;
  border-radius: 32px;
}
@media only screen and (max-width: 36.125em) {
  app-career-coaches .careerCoaches__submit-btn {
    width: 100%;
  }
}
app-career-coaches .careerCoaches__edit_programs {
  width: 100%;
}
app-career-coaches .careerCoaches__edit_programs > div {
  padding: 0.7rem;
}
app-career-coaches .careerCoaches__edit-dialog-form {
  width: 100%;
}
app-career-coaches .careerCoaches__edit-dialog-form .submit-btn {
  width: 100%;
  border-radius: 32px;
  margin-top: 2px;
}
app-career-coaches .careerCoaches .assign-cohort-btn {
  width: 7rem;
}
@media only screen and (max-width: 62em) {
  app-career-coaches .careerCoaches__institution-select > div {
    width: 100%;
  }
}
@media only screen and (max-width: 62em) {
  app-career-coaches .careerCoaches__program-filter .p-dropdown {
    width: 100%;
  }
}
@media only screen and (max-width: 96.25em) {
  app-career-coaches .careerCoaches__colgroup col {
    width: 135px;
  }
}
@media only screen and (max-width: 36.125em) {
  app-career-coaches .careerCoaches__colgroup col {
    width: 106px;
  }
}
app-career-coaches .careerCoaches__colgroup .canEdit-col {
  width: 99px;
}
app-career-coaches .table-td {
  text-align: center;
}
app-career-coaches .td-program {
  padding: 0 !important;
  text-align: center;
}

.idleDialog > div {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.idleDialog__wrapper {
  text-align: center;
  padding: 0 3rem;
}
@media only screen and (max-width: 36.125em) {
  .idleDialog__wrapper {
    padding: 0;
  }
}
.idleDialog__wrapper .countDown {
  font-size: 22px;
  font-weight: 900;
  padding: 0 3px;
  color: #414d5f;
  display: inline-block;
}
.idleDialog .p-dialog .p-dialog-header {
  border: none;
}
.idleDialog .p-dialog .p-dialog-content {
  border: none;
}
.idleDialog .idleDialog--btn {
  margin: 1.3rem 0 2rem 0;
  width: 13rem;
}
.idleDialog--icon {
  color: #007ad9;
  margin-bottom: 1.2rem;
}

body.color-scheme-dark .countDown {
  color: #007ad9;
}

app-broadcasts .p-panel .p-panel-content {
  white-space: pre-line;
}
app-broadcasts .high_priority .p-panel-titlebar {
  font-weight: bold;
  font-size: larger;
}

body[class*=color-scheme-light] app-broadcasts .high_priority .p-panel .p-panel-header {
  background-color: #545e72;
  color: white;
  border: 1px solid #545e72;
}

body[class*=color-scheme-dark] app-broadcasts .high_priority .p-panel .p-panel-header {
  color: white;
  background-color: #188ae2;
  border: 1px solid #188ae2;
}

app-answers-for-questions p-dropdown > div {
  width: 100%;
}
app-answers-for-questions .multiSelect > div {
  text-align: left;
  top: 0.9em;
  width: 100%;
}
app-answers-for-questions .calendar > span {
  width: 100%;
}
app-answers-for-questions .calendar > span > input {
  width: 100%;
}
app-answers-for-questions input {
  width: 100%;
}

app-manage-admissions .turned_off {
  display: flex;
  margin-top: 1rem;
}
app-manage-admissions .turned_off p-inputSwitch {
  margin: 0 0.6rem;
}

.nat-filters .p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 0;
}

app-nat-advisor-dashboard .nat-filter {
  padding-bottom: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
app-nat-advisor-dashboard .nat-filter p-multiselect {
  width: 22rem;
}
app-nat-advisor-dashboard p-calendar span {
  width: 100%;
}
app-nat-advisor-dashboard p-calendar span input {
  width: 100%;
}
app-nat-advisor-dashboard p-dropdown > div {
  width: 100%;
}
app-nat-advisor-dashboard input {
  height: 2.3rem;
  border: 1px solid #a6a6a6;
  padding: 6px;
  font-size: 15px;
}
app-nat-advisor-dashboard .button {
  width: 9rem;
}
app-nat-advisor-dashboard .button.selected {
  font-weight: bold;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
}
@media only screen and (max-width: 36.125em) {
  app-nat-advisor-dashboard .button {
    width: 100%;
    margin-top: 0.3rem;
  }
}
app-nat-advisor-dashboard .badge {
  font-weight: 400;
  line-height: unset;
  margin: 0;
}

body[class*=color-scheme-dark] app-nat-advisor-dashboard input {
  background-color: #3a4452;
  border: none;
  color: #fff;
}

app-national-admissions .p-paginator-bottom {
  margin-top: 1.5em;
}

app-courses-and-modules p-checkbox label {
  font-weight: 600 !important;
}

app-confirm-step .sidebar-component .p-sidebar {
  background-color: white !important;
  border: unset !important;
}
app-confirm-step .color-scheme-dark .sidebar-component .p-sidebar {
  background-color: #363f4c !important;
  border: unset !important;
}
app-confirm-step .confirm-step-component .color-scheme-dark .unselected-filter-button {
  background-color: transparent;
  border: 1px solid #fff2;
  color: #fff2;
}

body.color-scheme-dark app-confirm-step .sidebar-component .p-sidebar {
  background-color: #363f4c !important;
  border: unset !important;
}
body.color-scheme-dark app-confirm-step .unselected-filter-button {
  background-color: transparent;
  border: 1px solid #fff2;
  color: #fff2;
}

body.color-scheme-dark app-instructors-step .sidebar-component .p-sidebar {
  background-color: #363f4c !important;
  border: unset !important;
}

app-instructor-forecasting .page-title-box .page-title-h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding: 1rem 0;
  margin-top: 0;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .card-box .instructor-forecasting-box {
    padding: 0.6rem;
  }
}
app-instructor-forecasting .instructor-forecasting-label-title {
  color: #000;
  font-weight: 500;
  font-size: 18px;
}
app-instructor-forecasting .instructor-forecasting-box input {
  padding: 0.429em 0.5rem;
  min-width: unset;
  width: 19rem;
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box input {
    width: 15rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box input {
    width: 16rem;
  }
}
app-instructor-forecasting .instructor-forecasting-box .row {
  margin-bottom: 1rem !important;
}
app-instructor-forecasting .instructor-forecasting-box .p-dropdown {
  width: 19rem;
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box .p-dropdown {
    width: 15rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box .p-dropdown {
    width: 16rem;
  }
}
app-instructor-forecasting .instructor-forecasting-box:first-of-type label {
  flex: 0 0 8%;
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box:first-of-type label {
    flex: 0 0 12%;
  }
}
@media only screen and (max-width: 64em) {
  app-instructor-forecasting .instructor-forecasting-box:first-of-type label {
    flex: 0 0 14%;
  }
}
@media only screen and (max-width: 62em) {
  app-instructor-forecasting .instructor-forecasting-box:first-of-type label {
    flex: 0 0 20%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box:first-of-type label {
    flex: 0 0 100%;
  }
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row {
  margin-left: 0;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 75em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type {
    margin-top: 3rem;
  }
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .row {
  display: flex;
  align-items: center;
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .row p-inputswitch {
  margin-left: 0;
  display: flex;
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .label {
  padding-left: 0 !important;
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type label {
  flex: 0 0 26%;
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type label {
    flex: 0 0 27%;
  }
}
@media only screen and (max-width: 80em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type label {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .instructor-forecasting-label-title {
    flex: 0 0 50%;
  }
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .inputSwitch {
  display: flex;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type .inputSwitch {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 64em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type p-inputswitch {
    padding-left: 2rem !important;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .instructor-forecasting-row .col-6:last-of-type p-inputswitch {
    padding-left: 0 !important;
  }
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 {
  display: flex;
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 label {
  flex: 0 0 19%;
  padding: 0.429em 0.5rem;
}
@media only screen and (max-width: 96.25em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 label {
    flex: 0 0 29%;
  }
}
@media only screen and (max-width: 75em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 label {
    flex: 0 0 12%;
  }
}
@media only screen and (max-width: 64em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 label {
    flex: 0 0 14%;
  }
}
@media only screen and (max-width: 62em) {
  app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) .row .my-2 label {
    flex: 0 0 20%;
  }
}
app-instructor-forecasting .instructor-forecasting-box:nth-last-of-type(2) p-dropdown {
  margin: 0 !important;
}
@media only screen and (max-width: 62em) {
  app-instructor-forecasting .instructor-forecasting-box:last-of-type {
    text-align: center;
  }
}
@media only screen and (max-width: 62em) {
  app-instructor-forecasting .instructor-forecasting-box:last-of-type .instructor-forecasting-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-box:last-of-type .instructor-forecasting-btn-wrapper {
    flex-wrap: wrap;
  }
}
app-instructor-forecasting .instructor-forecasting-calendar {
  padding-left: 0 !important;
}
app-instructor-forecasting .row .my-2 .px-2 {
  padding-left: 0 !important;
}
app-instructor-forecasting .instructor-forecasting-btn {
  width: 13rem;
  margin: 0.5rem 1rem 0.5rem 0 !important;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting .instructor-forecasting-btn {
    width: 100%;
  }
}
app-instructor-forecasting p-tabview ul li {
  min-width: 7%;
  text-align: center;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-forecasting p-tabview ul li {
    width: 49%;
  }
}

body[class*=color-scheme-dark] app-instructor-forecasting .page-title-box .page-title-h1 {
  color: #fff;
}
body[class*=color-scheme-dark] app-instructor-forecasting .instructor-forecasting-label-title {
  color: #fff;
}

app-instructors .instructors__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 64em) {
  app-instructors .instructors__row {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors__row {
    justify-content: center;
    margin-bottom: 0;
  }
}
app-instructors .instructors__row .filter-btn {
  flex: 0 0 14%;
}
@media only screen and (max-width: 96.25em) {
  app-instructors .instructors__row .filter-btn {
    flex: 0 0 17%;
  }
}
@media only screen and (max-width: 64em) {
  app-instructors .instructors__row .filter-btn {
    flex: 0 0 22%;
  }
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors__row .filter-btn {
    flex: 0 0 100%;
  }
}
app-instructors .instructors__row .filter-btn i {
  width: unset;
}
app-instructors .instructors__row > * {
  flex: 0 0 18%;
  margin-right: 1rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 96.25em) {
  app-instructors .instructors__row > * {
    flex: 0 0 23%;
  }
}
@media only screen and (max-width: 64em) {
  app-instructors .instructors__row > * {
    margin-bottom: 1rem;
    flex: 0 0 30%;
  }
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors__row > * {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__row > * {
    flex: 0 0 100%;
    margin-right: 0;
  }
}
app-instructors .instructors__row > * > * {
  width: 100%;
}
app-instructors .instructors__row app-institution-dropdown p-dropdown > * {
  width: 100%;
}
app-instructors .instructors__row-buttons {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors__row-buttons {
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__row-buttons {
    flex-direction: column;
    margin-top: 0;
  }
}
app-instructors .instructors__row-buttons .btn {
  width: 12rem;
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__row-buttons .btn {
    width: 100%;
    margin-bottom: 0;
  }
}
app-instructors .instructors__row-buttons .btn:first-of-type {
  margin-right: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__row-buttons .btn:first-of-type {
    margin: 1.5rem 0 1.5rem 0;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__row-buttons .btn:last-of-type {
    margin-bottom: 4rem;
  }
}
app-instructors .instructors__row-buttons .btn:last-of-type i {
  margin-right: 6px;
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors__btns {
    display: flex;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors__btns {
    display: flex;
    flex-direction: column;
  }
}
app-instructors .instructors-filter-input {
  height: 38px;
}
@media only screen and (max-width: 62em) {
  app-instructors .instructors-filter-input {
    margin-right: 1rem;
    margin-bottom: 0;
    margin-top: 9px;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .instructors-filter-input {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
app-instructors .instructors__colgroup col {
  width: 250px;
}
app-instructors .instructors__colgroup col:nth-of-type(1), app-instructors .instructors__colgroup col:nth-of-type(9), app-instructors .instructors__colgroup col:nth-of-type(10), app-instructors .instructors__colgroup col:last-of-type {
  width: 160px;
}
app-instructors .instructors__colgroup col:nth-of-type(9) {
  width: 169px;
}
app-instructors .instructors__th {
  width: 6em;
}
app-instructors p-dropdown .p-dropdown .p-dropdown-label {
  text-align: left;
}
app-instructors p-multiselect .p-multiselect .p-multiselect-label {
  padding-right: 0;
  text-align: left;
}
@media only screen and (max-width: 36.125em) {
  app-instructors .mx-1 {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructors .p-paginator .p-paginator-pages .p-paginator-page {
    height: 2em;
    min-width: 1.4em;
  }
}
app-instructors app-fields-badges-template {
  margin-left: -7px;
}

app-instructors-area .instructor-view__zoom-link {
  word-break: break-all;
}

app-instructors-management .content {
  display: flex;
}
app-instructors-management .content-wrapper {
  display: flex;
  flex-direction: column;
}
app-instructors-management .title-links {
  width: 139px;
}
app-instructors-management .number {
  width: 100%;
  padding-right: 10px;
  line-height: 50px;
}
app-instructors-management .number:hover {
  text-decoration: underline;
}
app-instructors-management .number__url {
  overflow-wrap: anywhere;
}
app-instructors-management .credential-para {
  font-weight: 500;
}
app-instructors-management .credential-li {
  list-style: none;
  margin-bottom: 1rem;
}
app-instructors-management .credential-li a {
  width: 85%;
  margin-bottom: 9px;
}
app-instructors-management .credential-li a .credential_span {
  color: #000;
}
app-instructors-management p-button button {
  width: 8rem;
  margin-top: 1rem;
}
app-instructors-management .modalityPreferenceDialog .preference-dialog {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}
app-instructors-management .modalityPreferenceDialog .preference-dialog p-dropdown {
  width: 70%;
}
app-instructors-management .modalityPreferenceDialog .preference-dialog p-dropdown > div {
  width: 100%;
}
app-instructors-management .error-message {
  height: 100px;
}
app-instructors-management app-scheduled-lessons .noCallNoShow__title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
app-instructors-management app-scheduled-lessons .noCallNoShow__radioBbox {
  padding: 1.5rem 0 2.5rem 0;
  display: flex;
  justify-content: space-evenly;
}
app-instructors-management app-scheduled-lessons .noCallNoShow__btnBox {
  display: flex;
  justify-content: center;
}
app-instructors-management app-scheduled-lessons .noCallNoShow__btnBox button {
  width: 7rem;
}
app-instructors-management .univ_approval {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1.5rem 0;
}
app-instructors-management .univ_approval__box {
  display: flex;
  flex-direction: column;
  width: 47%;
}
app-instructors-management .univ_approval__box input {
  padding: 3px 0;
}
app-instructors-management .univ_app_btn {
  margin: 1rem 0 1.5rem 0;
}
app-instructors-management .university_approval {
  display: flex;
  flex-wrap: wrap;
}
app-instructors-management .university_approval__wrapper {
  display: flex;
  align-items: center;
  width: 50%;
}
@media only screen and (max-width: 62em) {
  app-instructors-management .university_approval__wrapper {
    width: 100%;
  }
}
app-instructors-management .university_approval__wrapper p {
  margin: 0.5rem 1rem 0.5rem 0;
}
app-instructors-management .university_approval__wrapper i {
  font-size: 20px;
  padding-right: 5px;
  cursor: pointer;
}
app-instructors-management .university_approval__icons {
  display: flex;
}
app-instructors-management .university_approval__link {
  text-decoration: underline;
  margin: 1rem 0;
}
app-instructors-management .hire_date_calendar {
  width: 100%;
}
app-instructors-management .hire_date_calendar span {
  width: 100%;
}
app-instructors-management .hire_date_calendar span input {
  width: 100%;
}

body[class*=color-scheme-dark] app-instructors-management .univ_approval__box input {
  background-color: unset;
  border: 1px solid #fff;
  color: #fff;
}

app-instructors-area .instructor-table th > td {
  word-wrap: break-word;
}
app-instructors-area .inst-area__table-multiselect > div {
  width: 100%;
}
app-instructors-area .inst-area__table-multiselect .p-multiselect .p-multiselect-label-container {
  padding-right: 0;
}
app-instructors-area .inst-area__table-dropdown > div {
  min-width: unset;
  width: 100%;
  margin-top: 8px;
}
app-instructors-area .callOut--link:hover {
  text-decoration: underline;
}
app-instructors-area .callOut--textarea {
  width: 100%;
  min-height: 8rem;
  margin-top: 1rem;
}
app-instructors-area .callOut--btn {
  float: right;
  width: 25%;
}
app-instructors-area .callOut--checkbox {
  margin: 1rem 0;
}
app-instructors-area .availability-dialog .availability-wrapper {
  height: 450px;
}
app-instructors-area .availability-dialog p-calendar span,
app-instructors-area .availability-dialog p-calendar input {
  width: 100%;
}
app-instructors-area .availability-dialog p-dialog .pb-2-5 {
  padding-top: 2.25rem !important;
}
app-instructors-area .availability-dialog .add-unavailability-button {
  display: flex;
  flex-direction: row-reverse;
}
app-instructors-area .availability-dialog .add-unavailability-button button {
  width: 24em;
}
app-instructors-area .delete-dialog p-dialog .p-dialog-content.p-widget-content {
  min-height: 100px;
}
app-instructors-area .min-h-500px {
  min-height: 500px;
}
app-instructors-area .switch-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
}
app-instructors-area .switch-wrapper .switch-view {
  display: flex;
  padding: 1.5rem 0 0rem 1.5rem;
}
app-instructors-area .switch-wrapper .switch-view p-inputSwitch {
  margin: 0 1rem;
}
app-instructors-area .switch-wrapper .switch-view p {
  color: #414d5f;
  font-weight: 500;
}
app-instructors-area app-extra-hour-requests p-calendar span,
app-instructors-area app-extra-hour-requests p-dropdown,
app-instructors-area app-extra-hour-requests p-dropdown > div,
app-instructors-area app-extra-hour-requests p-calendar input {
  width: 100%;
}
app-instructors-area app-extra-hour-requests .row {
  margin-bottom: 1em;
}
app-instructors-area app-instructor-table table p-dropdown .p-dropdown.p-component {
  min-width: unset;
}
app-instructors-area app-instructor-table table p-dropdown .p-dropdown .p-dropdown-trigger {
  width: 1.657rem;
}
app-instructors-area app-instructor-table table th {
  word-wrap: break-word;
}
@media only screen and (max-width: 96.25em) {
  app-instructors-area app-instructor-table .colgroup col {
    width: 150px;
  }
}
app-instructors-area app-instructor-table .table--filters {
  text-align: left;
}
app-instructors-area app-instructor-table .table--input {
  width: auto;
}
app-instructors-area app-instructor-table .table--dropdown > div {
  min-width: 100%;
}
app-instructors-area app-instructor-table .approved {
  color: #27a55f;
}
app-instructors-area app-instructor-table .rejected {
  color: #f34943;
}

@media only screen and (max-width: 64em) {
  app-scheduled-lessons select {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 62em) {
  app-scheduled-lessons select {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 62em) {
  app-scheduled-lessons p-calendar .p-calendar {
    margin-top: 1rem;
  }
}
app-scheduled-lessons .agreement-dialog p-dialog > div > div {
  width: 40rem;
  text-align: center;
}
app-scheduled-lessons .agreement-dialog p-dialog > div .p-dialog-header {
  display: unset;
}
app-scheduled-lessons .agreement-dialog p-dialog > div a {
  margin: 1rem 0;
}

app-instructor-callouts p-tabView ul li {
  width: 9rem;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts p-tabView ul li {
    width: 49%;
  }
}
app-instructor-callouts .callOuts-table td {
  overflow: hidden;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table__filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table__filter-wrapper * {
    width: 100%;
  }
}
app-instructor-callouts .callOuts-table input {
  width: 14%;
}
@media only screen and (max-width: 62em) {
  app-instructor-callouts .callOuts-table input {
    width: 32%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table input {
    width: 100%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table .program-filter {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table .cohort-filter {
    margin-top: 1rem;
  }
}
app-instructor-callouts .callOuts-table--td {
  padding: 0;
}
app-instructor-callouts .callOuts-table .p-datatable-tbody > tr > td {
  text-align: center;
}
@media only screen and (max-width: 36.125em) {
  app-instructor-callouts .callOuts-table select {
    margin-bottom: 1rem;
  }
}
app-instructor-callouts .reason {
  padding: 1rem;
}
app-instructor-callouts .p-dialog .p-dialog-header {
  padding: 1em 2em;
}
app-instructor-callouts .show-reason {
  color: #007ad9;
  cursor: pointer;
}
app-instructor-callouts .show-reason:hover {
  text-decoration: underline;
}
app-instructor-callouts .agreement-dialog p-dialog > div {
  text-align: center;
}
app-instructor-callouts .agreement-dialog p-dialog > div a {
  margin: 1rem 0;
}

app-students .custom-spinner {
  font-size: 5em;
}
app-students .centerInLarge {
  text-align: center;
}
@media only screen and (max-width: 96.25em) {
  app-students .row--box {
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .row--box {
    width: 100%;
    text-align: center;
    padding-right: 5%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .row--box button {
    width: 79%;
  }
}
app-students .students-main-title {
  text-align: center;
}
app-students .p-fileupload .p-fileupload-content {
  padding: 0;
}
app-students .p-fileupload .p-fileupload-buttonbar .p-button {
  width: 15rem;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 0;
  float: left;
}
@media only screen and (max-width: 62em) {
  app-students .p-fileupload .p-fileupload-buttonbar .p-button {
    width: 12rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-bottom: 1rem;
    width: 15rem;
  }
}
app-students .p-fileupload .p-fileupload-buttonbar .p-button span:first-of-type {
  left: unset;
  position: unset;
  top: unset;
  margin-top: unset;
}
app-students .p-fileupload .p-fileupload-buttonbar .p-button .p-button-text {
  padding: 0.5rem 0.3rem;
}
app-students .p-fileupload .p-fileupload-buttonbar .p-button span .p-button-text-icon-left {
  left: unset;
  position: unset;
  top: unset;
  margin-top: unset;
}
app-students .p-widget p-multiselect {
  width: 18rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-left: -0.2rem;
}
app-students .p-widget input {
  width: 18rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  line-height: 24px;
}
@media only screen and (max-width: 62em) {
  app-students .p-widget input {
    margin-right: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .p-widget input {
    width: 17rem;
  }
}
app-students .p-widget p-dropdown,
app-students .p-widget p-multiselect {
  margin-right: 1rem;
  width: 18rem;
  margin-left: 0;
}
app-students .p-widget p-dropdown > div,
app-students .p-widget p-multiselect > div {
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 62em) {
  app-students .p-widget p-dropdown,
app-students .p-widget p-multiselect {
    width: 100%;
    margin-right: 0;
  }
}
app-students .p-widget .p-dropdown.p-dropdown-clearable {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 62em) {
  app-students .p-widget .p-dropdown.p-dropdown-clearable {
    width: 100%;
  }
}
app-students .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
  width: 16.9rem;
  text-align: left;
}
@media only screen and (max-width: 96.25em) {
  app-students .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    width: 17rem;
  }
}
@media only screen and (max-width: 62em) {
  app-students .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    width: 18rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    width: 17rem;
  }
}
@media only screen and (max-width: 23.4375em) {
  app-students .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    width: 16rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .p-fileupload .p-fileupload-buttonbar {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
app-students .m-2 {
  margin: 0 1rem 1rem 0 !important;
}
@media only screen and (max-width: 62em) {
  app-students .filter-btn_wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
app-students .students__row .card-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 62em) {
  app-students .students__row .card-box {
    text-align: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .students__row .card-box {
    padding: 1rem 0;
  }
}
app-students .students__row--box {
  padding-bottom: 1%;
}
app-students .students__card-box .spinner-box {
  display: flex;
  height: 400px;
}
app-students .students-btn {
  width: 15rem;
  height: 2.4rem;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 64em) {
  app-students .students-btn {
    width: 16rem;
  }
}
@media only screen and (max-width: 62em) {
  app-students .students-btn {
    width: 50%;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .students-btn {
    width: 100%;
  }
}
app-students .students-btn .p-button-text-icon-left {
  left: unset;
  position: unset;
  top: unset;
  margin-top: unset;
}
app-students .students__filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: self-start;
}
@media only screen and (max-width: 62em) {
  app-students .students__filters {
    justify-content: space-evenly;
  }
}
app-students .students__filters .students-btn .p-button-text {
  padding: 0;
}
@media only screen and (max-width: 62em) {
  app-students .students__wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
app-students .students__wrapper .custom-select {
  width: fit-content;
  float: right;
}
@media only screen and (max-width: 62em) {
  app-students .students__wrapper .custom-select {
    width: 50%;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students .students__wrapper .custom-select {
    width: 100%;
  }
}
@media only screen and (max-width: 96.25em) {
  app-students .students__colgroup col {
    width: 15rem;
  }
}
@media only screen and (max-width: 96.25em) {
  app-students .students__colgroup .last {
    width: 9rem;
  }
}
app-students #students-btn-download {
  width: 15rem;
  height: 2.4rem;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 64em) {
  app-students #students-btn-download {
    width: 16rem;
  }
}
@media only screen and (max-width: 62em) {
  app-students #students-btn-download {
    width: 50%;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-students #students-btn-download {
    width: 100%;
  }
}
app-students #students-btn-download .p-button-text {
  padding: 0 0.3rem;
}
@media only screen and (max-width: 36.125em) {
  app-students .p-paginator .p-paginator-pages .p-paginator-page {
    height: 2em;
    min-width: 1.4em;
  }
}
app-students .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon,
app-students .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  margin-top: -0.5em;
  color: #007ad9;
}
app-students .importValidationDialog > div {
  width: 85%;
}
app-students .exportStudentData > div {
  width: 28em;
  height: 300px;
}
app-students .p-datatable-scrollable-header {
  width: 100%;
}
app-students .p-datatable-scrollable-wrapper {
  margin-top: 3%;
}

.newEnrolment .p-dropdown {
  width: 100%;
}

@media only screen and (max-width: 96.25em) {
  app-cohort-and-student-counts colgroup col {
    width: 304px;
  }
}
app-cohort-and-student-counts .student-input:first-of-type {
  margin-right: 1rem;
}
@media only screen and (max-width: 64em) {
  app-cohort-and-student-counts .student-input:first-of-type {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

app-student .enrollment__row {
  display: flex;
}
@media only screen and (max-width: 64em) {
  app-student .enrollment__row {
    flex-direction: column;
  }
}
app-student .enrollment__box--1 {
  width: 50%;
}
@media only screen and (max-width: 96.25em) {
  app-student .enrollment__box--1 {
    width: 60%;
  }
}
@media only screen and (max-width: 64em) {
  app-student .enrollment__box--1 {
    width: 100%;
  }
}
app-student .reEntry-btn {
  width: 7rem;
}
app-student .reEntry-sub {
  width: 5rem;
}
app-student app-student-enrollments .create_popup_field {
  width: 100%;
}

app-default-popup .p-widget input.genereted_sis {
  width: 30px;
  height: 26px;
}

app-student-profile-career-services .summary {
  margin-left: 0;
}
app-student-profile-career-services .validation-error {
  color: red;
  font-size: 14px;
}

app-new-student-form .p-dialog .p-dialog-header {
  text-align: center;
}
app-new-student-form .new_student_form {
  padding-top: 1rem;
}
app-new-student-form .new_student_form p-dropdown > div {
  width: 90%;
}
app-new-student-form .new_student_form--checkbox {
  background-color: #f9f9f9;
  border-radius: 7px;
  padding: 1rem 1rem 1rem 1.3rem;
}
app-new-student-form .new_student_form--subtitle {
  padding: 2rem 0 1rem 0.5rem;
  font-size: 18px;
  font-weight: 600;
}
app-new-student-form .new_student_form--subtitle:last-of-type {
  padding-top: 1rem;
}
app-new-student-form .new_student_form--box {
  display: flex;
  flex-wrap: wrap;
}
app-new-student-form .new_student_form .form-group {
  width: 50%;
  padding-left: 0.5rem;
}
@media only screen and (max-width: 62em) {
  app-new-student-form .new_student_form .form-group {
    width: 48%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-new-student-form .new_student_form .form-group {
    width: 100%;
  }
}
app-new-student-form .new_student_form .form-group .element {
  width: 19rem;
}
app-new-student-form .new_student_form .form-group .intro_letter > div {
  width: 90%;
}
@media only screen and (max-width: 96.25em) {
  app-new-student-form .new_student_form .form-group .intro_letter > div {
    width: 92%;
  }
}
@media only screen and (max-width: 36.125em) {
  app-new-student-form .new_student_form .form-group .intro_letter > div {
    width: 100%;
  }
}
app-new-student-form .new_student_form .form-group .intro_letter--span {
  font-size: 13px;
}
app-new-student-form .new_student_form--btnContainer {
  width: 100%;
  text-align: right;
  margin: 1rem 0;
}
app-new-student-form .new_student_form--btnContainer .btn {
  width: 35%;
}
app-new-student-form .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
  width: 21.5rem;
}
app-new-student-form .entry_point .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
  margin-top: -1em;
}
@media only screen and (max-width: 62em) {
  app-new-student-form .p-widget .p-dropdown.p-dropdown-clearable {
    width: 91%;
  }
}

app-new-student-form .newStudentDialog .p-dialog-mask {
  width: 100%;
}
app-new-student-form .newStudentDialog .p-dialog {
  width: 70rem;
}

body[class*=color-scheme-dark] app-new-student-form .new_student_form--checkbox {
  background-color: unset;
}

app-career-service-placement-priorities .placementPriorities {
  padding: 1rem;
}
app-career-service-placement-priorities .placementPriorities__filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
app-career-service-placement-priorities .placementPriorities__filters > * {
  margin-right: 1.3rem;
}
@media only screen and (max-width: 62em) {
  app-career-service-placement-priorities .placementPriorities__filters > * {
    margin-right: 0;
    width: 100%;
  }
}
app-career-service-placement-priorities .placementPriorities__input {
  width: 15rem;
  height: 38px;
}
@media only screen and (max-width: 62em) {
  app-career-service-placement-priorities .placementPriorities__input {
    width: 100%;
  }
}
@media only screen and (max-width: 62em) {
  app-career-service-placement-priorities .placementPriorities p-multiselect {
    width: 100%;
  }
}
app-career-service-placement-priorities .placementPriorities p-multiselect > div {
  width: 15rem;
}
@media only screen and (max-width: 62em) {
  app-career-service-placement-priorities .placementPriorities p-multiselect > div {
    width: 100%;
    margin-top: 1rem;
  }
}
app-career-service-placement-priorities .placementPriorities p-multiselect > div .p-multiselect-label-container {
  padding-right: 1em;
  text-align: left;
}
@media only screen and (max-width: 62em) {
  app-career-service-placement-priorities .placementPriorities p-multiselect > div .p-multiselect-label-container {
    margin-right: 0;
  }
}
@media only screen and (max-width: 96.25em) {
  app-career-service-placement-priorities .placementPriorities__colgroup col {
    width: 135px;
  }
}
@media only screen and (max-width: 36.125em) {
  app-career-service-placement-priorities .placementPriorities__colgroup col {
    width: 106px;
  }
}
app-career-service-placement-priorities .link_url {
  word-wrap: break-word;
}
app-career-service-placement-priorities .student_summary_text {
  padding: 1.5rem 0.5rem;
}
app-career-service-placement-priorities p-paginator {
  padding: 1rem;
}
app-career-service-placement-priorities p-paginator .p-paginator {
  padding: 1.5rem 0 0.5rem 0;
}
app-career-service-placement-priorities .empty-students {
  text-align: center;
}

app-find-intro-course p-calendar .p-inputtext {
  margin: 0;
}
app-find-intro-course p-calendar span {
  width: 100%;
}
app-find-intro-course p-calendar span .p-widget {
  width: 100%;
}
app-find-intro-course .no-wrap {
  flex-wrap: nowrap;
}
app-find-intro-course p-listbox li.p-listbox-item.p-corner-all.ng-star-inserted {
  padding: 1rem;
  border-bottom: 1px solid #434e5e;
}
app-find-intro-course p-progressSpinner .p-progress-spinner-svg {
  height: 2rem;
}
app-find-intro-course p-progressSpinner .p-progress-spinner {
  height: 1rem;
  margin-top: 0.25rem;
}

app-student-enrollments .newEnrolment p-dialog > .p-dialog {
  width: 22rem;
}
app-student-enrollments .newEnrolment .p-dialog-mask {
  width: 100%;
}
app-student-enrollments .sf-id {
  font-size: 13px;
}
app-student-enrollments app-create-student-enrollment {
  color: red;
}
app-student-enrollments app-create-student-enrollment app-default-popup {
  color: green;
}
app-student-enrollments app-create-student-enrollment app-default-popup .sf-op-id {
  height: 2.2rem;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 2px 5px;
}

app-cohort-audit .overflow-cohorts {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 12rem;
}
app-cohort-audit .header-title {
  text-align: center;
  padding-top: 1rem;
}
app-cohort-audit .row {
  align-items: center;
  justify-content: center;
}
app-cohort-audit .row p-dropdown > div {
  width: 16rem;
}
app-cohort-audit .upper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-cohort-audit .upper .cohort_dropdown > div {
  width: 15rem;
  margin-bottom: 1rem;
}
app-cohort-audit .down .cohort-box {
  text-align: center;
}
app-cohort-audit .down .cohort-box p > span {
  font-weight: 600;
  padding-right: 5px;
}
app-cohort-audit .down .row {
  margin-bottom: 3rem;
}
app-cohort-audit .down .table-wrapper {
  margin-bottom: 5rem;
}
app-cohort-audit .down .table-wrapper:last-of-type {
  margin-bottom: 3rem;
}
app-cohort-audit .down .table-wrapper p {
  margin-bottom: 4px !important;
}
app-cohort-audit .down .table-wrapper--head {
  display: flex;
  align-items: baseline;
}
app-cohort-audit .down .table-wrapper--head .label {
  padding-right: 1rem;
  padding-left: 3px;
  font-weight: 600;
  font-size: 20px;
}
app-cohort-audit .down .table-wrapper .center {
  text-align: center;
}

app-cohorts-send-contracts .senior_fees {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5%;
}
app-cohorts-send-contracts .senior_fees p {
  margin-left: 0.5rem;
}

app-cohorts .edit_icons a {
  color: #333333;
}
app-cohorts .add-module-dialog .p-dialog .p-dialog-content,
app-cohorts .replan-cohort-dialog .p-dialog .p-dialog-content,
app-cohorts .edit-basic-cohort-info .p-dialog .p-dialog-content {
  overflow: unset;
}
app-cohorts app-attendance .attendanceLogsDialog p-dialog > div {
  width: 70rem;
}
app-cohorts app-attendance .attendanceLogsDialog p-dialog > div .p-dialog-header {
  padding-left: 2.6rem;
}

app-agreement-popup .popup_agreement_is_opend {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
}
app-agreement-popup .popup_agreement_is_opend span {
  color: #333333;
  font-weight: 400 !important;
}

body[class*=color-scheme-dark] app-cohorts .edit_icons a {
  color: #ced4da;
}
body[class*=color-scheme-dark] app-agreement-popup .popup_agreement_is_opend {
  background-color: #323a46;
  border: 1px solid #ffffff1a;
}
body[class*=color-scheme-dark] app-agreement-popup .popup_agreement_is_opend span {
  color: #ffffff;
  font-weight: 400 !important;
}

.attendance-icon {
  transform: scale(1.25);
  cursor: pointer;
}

app-push-cohorts p-panel .p-panel-content {
  height: 30em;
}
app-push-cohorts #pushCohortsListBox .p-listbox-header .p-listbox-filter-container {
  width: 100%;
}
app-push-cohorts #mainPanel.noCohortSelected .p-panel-content {
  display: flex;
}
app-push-cohorts app-cohort-push-section-view app-push-cohorts-create-or-edit p-dropdown.pushInput .p-dropdown, app-push-cohorts app-cohort-push-section-view app-push-cohorts-create-or-edit p-calendar .p-calendar input {
  width: 16em;
}

app-cohort-top-info-date-editor p-calendar span .p-inputtext {
  padding-top: 2px;
  padding-bottom: 2px;
}

.text-dialog__text {
  padding: 1rem 0 3rem 0;
}

app-toggle-teaxtarea .text-wrapper {
  margin-left: 0;
}
app-toggle-teaxtarea .text-wrapper__para {
  margin-left: 18px;
}
app-toggle-teaxtarea .text-wrapper__textarea {
  width: 90%;
  height: 10rem;
  border: 1px solid #a6a6a6;
}
app-toggle-teaxtarea .text-wrapper .btn-outline-info {
  width: 8rem;
}
app-toggle-teaxtarea .text-wrapper .btn-rounded {
  width: 5rem;
  float: left;
  margin-left: 18px;
}
app-toggle-teaxtarea .text-wrapper__text {
  font-size: 17px;
}
app-toggle-teaxtarea .text-wrapper--error {
  display: inline-block;
  margin: 1rem 0 1.5rem 0;
  color: red;
  width: 100%;
}
app-toggle-teaxtarea .text-wrapper textarea {
  width: 37%;
  height: 13rem;
}
app-toggle-teaxtarea .text-wrapper button {
  width: 10rem;
  float: right;
}
app-toggle-teaxtarea .pi-spinner {
  font-size: 5em;
}

app-error-from-server .error-wrapper {
  display: flex;
}
app-error-from-server .error-wrapper .container {
  display: flex;
  height: 100%;
  width: 100%;
}
app-error-from-server .error-wrapper .container .row {
  justify-content: center;
  align-self: center;
  margin: auto;
}

app-general-spinner .spinner-wrapper {
  display: flex;
}
app-general-spinner .spinner-wrapper .container {
  display: flex;
  height: 100%;
  width: 100%;
}
app-general-spinner .spinner-wrapper .container .row {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: auto;
}
app-general-spinner .spinner-wrapper .container .row i {
  font-size: 5em;
}

app-program-managers .program_multiselect > div {
  width: 100%;
}
app-program-managers app-field-multi-select p-multiselect .p-multiselect {
  width: 100%;
}

.dropdown_slug {
  font-size: 14px;
  float: right;
  margin-top: 4px;
}

.program_multiselect {
  width: 100%;
}

#assign-relationships-listbox > div > div.p-listbox-list-wrapper {
  height: 20em;
}

#enrollmentsListBox > div > div.p-listbox-list-wrapper {
  height: 20em;
}

app-question-management .questions__btns {
  text-align: center;
  margin-bottom: 1rem;
}
app-question-management .questions__btns button {
  margin-right: 1rem;
  border-radius: 32px;
  padding: 0.4rem 0;
  width: 13rem;
  margin-bottom: 1rem;
}
app-question-management .dialog_form p-multiselect > div {
  width: 100%;
}
app-question-management .dialog_form .form-check {
  margin: 8px 0;
  padding-left: 0;
}
app-question-management .dialog_form input[type=checkbox] {
  width: 23px;
  height: 25px;
}
app-question-management .dialog_form .form-check-input {
  margin-left: 0;
  margin-bottom: 8px;
}
app-question-management .dialog_form button {
  width: 36%;
  float: right;
}
app-question-management .p-chips > ul.p-inputtext .p-chips-token {
  margin-bottom: 5px;
}
app-question-management .show_questions_dialog .small_th {
  width: 7rem;
  text-align: center;
}
@media only screen and (max-width: 62em) {
  app-question-management .show_questions_dialog .small_th {
    width: 4rem;
  }
}
app-question-management .reorder_container {
  text-align: right;
  position: sticky;
  top: 2%;
  z-index: 9999;
  margin-bottom: 8px;
}
app-question-management .orderQuestionBtn {
  border-radius: 32px;
  padding: 0.8rem 1.1rem;
}
app-question-management .small_th {
  width: 7rem;
  text-align: center;
}
@media only screen and (max-width: 62em) {
  app-question-management .small_th {
    width: 4rem;
  }
}
app-question-management .th_center {
  width: 36%;
  text-align: center;
}
app-question-management .questions_btn {
  border-radius: 32px;
}
@media only screen and (max-width: 36.125em) {
  app-question-management .questions_btn span {
    font-size: 13px;
  }
}
app-question-management app-create-question-dialog .p-dropdown-label {
  font-size: 16px;
}

body[class*=color-scheme-dark] app-question-management p-chips .p-chips > ul.p-inputtext .p-chips-input-token input {
  color: #fff;
}

app-extended .card-box .btn {
  width: 14rem;
}
@media only screen and (max-width: 36.125em) {
  app-extended .card-box .btn {
    margin-top: 1rem;
  }
}
app-extended .card-box p-dropdown > * {
  width: 20rem;
}
app-extended .extended__card-box {
  text-align: center;
}
app-extended .extended__titles {
  text-align: center;
}
app-extended .extended__titles button {
  margin-right: 1rem;
  border-radius: 32px;
}
app-extended .module_th {
  width: 5em;
}
app-extended p-dropdown > div {
  width: 100%;
}
app-extended .inputs_wrapper {
  margin-top: 1rem;
}
app-extended .inputs_wrapper > * {
  margin-top: 1rem;
}
app-extended .p-spinner .p-spinner-button.p-spinner-up,
app-extended .p-spinner .p-spinner-button.p-spinner-down {
  display: none;
}
app-extended .p-spinner .p-spinner-input {
  background: none;
  color: white;
  height: 38px;
}
app-extended p-spinner .p-corner-all, app-extended p-spinner .p-corner-all {
  width: 100%;
}
app-extended .box {
  display: flex;
}
app-extended .box--inner:nth-child(1), app-extended .box--inner:nth-child(2) {
  margin-right: 2rem;
}
app-extended .add_dialog p-dialog > .p-dialog {
  width: 42rem;
}
app-extended .add_dialog .submit {
  float: right;
  width: 32%;
}

app-introductory .button-list {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 62em) {
  app-introductory .button-list {
    justify-content: center;
  }
}
@media only screen and (max-width: 36.125em) {
  app-introductory .button-list {
    flex-direction: column;
  }
}
app-introductory button {
  width: 13rem;
  border-radius: 32px;
}
@media only screen and (max-width: 36.125em) {
  app-introductory button {
    width: 100%;
  }
}
app-introductory button:first-of-type {
  margin-right: 1rem;
}
@media only screen and (max-width: 36.125em) {
  app-introductory button:first-of-type {
    margin-bottom: 1rem;
  }
}
app-introductory p-dialog .form-group button {
  width: 100%;
  border-radius: 32px;
}
@media only screen and (max-width: 62em) {
  app-introductory .introductory__row-box {
    text-align: center;
  }
}
app-introductory .introductory__button {
  border-radius: 32px;
  margin: 1rem 0;
}
@media only screen and (max-width: 62em) {
  app-introductory .introductory__button {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 36.125em) {
  app-introductory .introductory__button {
    margin-bottom: 0;
    width: 100%;
  }
}
app-introductory .introductory__button .p-button-icon-left {
  right: 65%;
}
@media only screen and (max-width: 36.125em) {
  app-introductory .introductory__button:last-of-type {
    margin-bottom: 2rem;
  }
}
app-introductory .fees_top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
app-introductory .fees_top--para {
  margin-right: 1rem;
  display: flex;
  align-items: baseline;
  font-size: 15px;
}
app-introductory .fees_top--para p {
  margin-left: 3px;
  line-height: 0;
  font-weight: 600;
}
app-introductory .p-spinner .p-spinner-button.p-spinner-up,
app-introductory .p-spinner .p-spinner-button.p-spinner-down {
  display: none;
}
app-introductory .p-spinner .p-spinner-input {
  background: none;
  color: white;
  height: 38px;
}
app-introductory p-spinner .p-corner-all, app-introductory p-spinner .p-corner-all {
  width: 100%;
}
app-introductory .add_dialog p-dialog > .p-dialog {
  width: 47rem;
}
app-introductory .add_dialog .box {
  display: flex;
}
app-introductory .add_dialog .box--inner:nth-child(1), app-introductory .add_dialog .box--inner:nth-child(2) {
  margin-right: 2rem;
}
app-introductory .add_dialog .btn-box {
  text-align: right;
}
app-introductory .add_dialog .btn-box .submit {
  width: 35%;
  margin-right: 0;
}

app-attendance .container-100-h {
  height: 100vh;
}
app-attendance .updating-font-size {
  font-size: 1em;
}
app-attendance .font-size-5em {
  font-size: 5em;
}
app-attendance .p-b-6em {
  padding-bottom: 6em;
}
app-attendance .parent-statuses > div {
  width: 100%;
}
app-attendance .sub-status > div {
  width: 100%;
}

app-course-request .Lead {
  background-color: #00a806;
  color: #fff;
}
app-course-request .Associate {
  background-color: #b58416;
  color: #fff;
}
app-course-request span.position-rounded {
  padding: 0.2rem 1rem;
  border-radius: 6px;
  display: block;
  width: fit-content;
}
app-course-request app-table-plus-body .tp-row {
  border: unset !important;
  border-left: unset !important;
  background: #373e4b !important;
  cursor: pointer;
}
app-course-request app-table-plus-body .tp-row:hover {
  background: #596272 !important;
}
app-course-request app-table-plus-body .tp-row .position span.position-rounded {
  padding: 0.25rem 1rem;
  border-radius: 6px;
  display: block;
  width: fit-content;
}
app-course-request app-table-plus-body .tp-row .position .Lead {
  background-color: #00a806;
  color: #fff;
}
app-course-request app-table-plus-body .tp-row .position .Associate {
  background-color: #bd8508;
  color: #fff;
}
app-course-request .popup {
  width: max-content;
}
app-course-request .link {
  color: #fff;
  text-decoration: underline;
}
app-course-request .green {
  color: #29a55f;
}

p-sidebar .p-sidebar {
  background: #363f4c;
  border: none;
}
p-sidebar .cohort {
  background-color: #545f70;
  color: aliceblue;
}
p-sidebar .program {
  background-color: #1b8ae3;
  color: aliceblue;
}
p-sidebar .position {
  background-color: #00a806;
  color: aliceblue;
}
p-sidebar .information-list {
  list-style: none;
}
p-sidebar .information-list li {
  background: #313844;
  color: #fff;
}
p-sidebar .box-information {
  overflow-y: scroll;
  height: 75vh;
}
p-sidebar .close-panel {
  background-color: transparent;
  border: 1px solid #1563b7;
}

app-default-popup .discard-button {
  background-color: #4d5865 !important;
  border: none !important;
}
app-default-popup .discard-button:hover {
  transition: 0.4s all;
  background-color: #414b57 !important;
}
app-default-popup .save-button {
  background-color: #356cad !important;
  border: 1px solid #356cad !important;
}
app-default-popup .save-button-disabled {
  background-color: #ffffff00 !important;
  border: 1px solid #356cad !important;
}
app-default-popup .color-text {
  color: #fff;
}
app-default-popup .save-button:hover {
  transition: 0.4s all;
  background-color: #225797 !important;
  border: 1px solid #225797 !important;
}
app-default-popup textarea {
  resize: none;
}

app-instructor-table [data-varsion="2"] .btn-cancel-pending-call-out {
  width: 7em;
  text-align: center;
  display: inherit;
  padding: 0.25rem !important;
  background: #ba0000;
  border: 0px !important;
}
app-instructor-table [data-varsion="2"] .btn-call-out {
  width: 7em;
  text-align: center;
  display: inherit;
  padding: 0.25rem !important;
  background: none !important;
  border: 1px solid #ba0000 !important;
}
app-instructor-table [data-varsion="2"] th, app-instructor-table [data-varsion="2"] td, app-instructor-table [data-varsion="2"] tr, app-instructor-table [data-varsion="2"] tbody, app-instructor-table [data-varsion="2"] table {
  border: none !important;
}

body[class*=color-scheme-light] app-review-send .details-wrapper > .overview-card {
  background-color: #F4F3F4;
}
body[class*=color-scheme-light] app-review-send .fee-table-wrapper tr:nth-child(odd) {
  background-color: #F4F3F4 !important;
}
body[class*=color-scheme-light] app-review-send .fee-table-header,
body[class*=color-scheme-light] app-review-send .fee-table-header th {
  color: #495260 !important;
}

body[class*=color-scheme-dark] app-review-send .details-wrapper > .overview-card {
  background-color: #495260;
}
app-question-management {
  $root: &;

  & .questions {
    //text-align: center;

    &__btns {
      text-align: center;
      margin-bottom: 1rem;

      & button {
        margin-right: 1rem;
        border-radius: $br-radius-1;
        padding: 0.4rem 0;
        width: 13rem;

        @include respond (xs) {}
        margin-bottom: 1rem;
      }
    }
  }

  & .dialog_form {
    & p-multiselect > div {
      width: 100%;

    }

    & .form-check {
      margin: 8px 0;
      padding-left: 0;
    }

    & input[type="checkbox"]{
    width: 23px;
    height: 25px;
  }

    & .form-check-input {
      margin-left: 0;
      margin-bottom: 8px;
    }

    & button {
      width: 36%;
      float: right;
    }
  }

  & .p-chips > ul.p-inputtext .p-chips-token {
    margin-bottom: 5px;
  }

  & .show_questions_dialog {

    .small_th {
      width: 7rem;
      text-align: center;

      @include respond(md){
        width: 4rem;
      }
    }
  }


  & .reorder_container{
    text-align: right;
    position: sticky;
    top: 2%;
    z-index: 9999;
    margin-bottom: 8px;
  }

  & .orderQuestionBtn {
    border-radius: $br-radius-1;
    padding: 0.8rem 1.1rem;
  }

  .small_th {
    width: 7rem;
    text-align: center;

    @include respond(md){
      width: 4rem;
    }
  }

  .th_center{
    width: 36%;
    text-align: center;
  }

  & .questions_btn {
    border-radius: $br-radius-1;

    & span {
      @include respond(xs) {
        font-size: 13px;
      }
    }
  }

  app-create-question-dialog {
    .p-dropdown-label {
      font-size: 16px;
    }
  }
}

body[class*="color-scheme-dark"] {
  app-question-management {
    $root: &;

    & p-chips {
      & .p-chips > ul.p-inputtext .p-chips-input-token input {
        color: #fff;
      }
    }


  }
}


app-introductory-to-extended-conversion {
  $root: &;

  & .empty-cohort {
    text-align: center;
  }

  & .card-box.upper {
    & .row {
      align-items: center;
      justify-content: center;

      & .p-dropdown-panel{
        width: 20rem;
      }

      & .p-dropdown {
        min-width: unset;
        width: 20rem;

        @include respond(xs) {
          width: 100%;
        }
      }
    }

    & .card-box {
      margin-bottom: 0;
      text-align: center;

      @include respond(xs) {
        padding: 0;
        text-align: center;
      }

      p-dropdown {
        @include respond(xs) {
          width: 100%;
        }

        &>div {
          @include respond(xs) {
            width: 100%;
          }
        }
      }
    }
  }

  .filter-wrapper {
    display: flex;
    flex-wrap: wrap;

    &>* {
      width: 15rem;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include respond(xs) {
        width: 100%;
        margin-right: 0;
      }
    }

    & .p-dropdown {
      width: 100%;
    }

    .convert-btn {
      border-radius: $br-radius-1;
    }

    & .intro-dropdown {
      text-align: left;
    }
  }

  & tbody {
    tr {
      td {
        &:last-of-type {
          text-align: center;
        }
      }
    }
  }

  & .dialog-window {

    & .p-dialog {
      max-width: 1000px;
    }

    & .p-dialog .p-dialog-header {
      padding: 0.5em 0.5em 0 0;
    }

    & .p-dialog .p-dialog-header {
      padding: 0.5em 0.5em 0 0;
    }

    .row-box {
      margin: 1rem 0;
      //text-align: center;

      &--inner {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        & p {
          margin-right: 3rem;
        }
      }

      & .sf-text {
        margin-top: 6px;
        font-size: 13px;
      }
    }

    .dialog-btn {
      padding: 0 0 3px 0;
      float: right;
      width: 10rem;

      .p-button.p-button-text-only .p-button-text {
        padding: 0.4em 1em;
      }
    }

    .p-dialog .p-dialog-content {
      border: none;
    }

    p-dropdown {
      .p-dropdown {
        min-width: unset;
        width: 90%;

        &:first-of-type {
          margin-right: 8px;
        }
      }
    }

  }

  p-dropdown {
    text-align: left;
  }

  & .colgroup {
    & col {
      @include respond(laptops) {
        width: 230px;
      }
    }
  }

  & .converted-intro-spinner {
    width: 20px;
    height: 20px;
  }

  & .choose-cohort-dropdown {
    width: 14rem;
  }

  & .p-dropdown-items-wrapper {
    max-height: 200px;
    overflow: auto;
  }


}

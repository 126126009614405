  app-general-spinner {
    $root: &;
  & .spinner-wrapper {
    display:flex;

    & .container {
      display: flex;
      height: 100%;
      width: 100%;

      & .row {
        display: flex;
        justify-content: center;
        align-self: center;
        margin:auto;

        & i {
          font-size: 5em
      }
    }
  }
  }
  }


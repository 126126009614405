// ===Colors===
$color-1: #17a2b8;
$info-color:#007ad9;

// ===Text Colors===
$text-color-dark:#000;
$text-color-light:#fff;
$bg-color-1: #f1f5f7;

//===Font Weights===
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-book: 350;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

// Desktop text sizes
$dsk-headline--1: 20px;
$dsk-article-headline-1: 18px;

//Border-rdius
$br-radius-1: 32px;
$br-radius-8: 8px;

//Elements with
$tab-with: 9rem;
$input-with: 14%;
$filer-with: 18rem;

$ipad-input-with: 32%;

app-career-coaches {
  $root: &;

  & p-tabview{
    & .p-tabview.p-tabview-top .p-tabview-nav li {
      width: 11rem;
      & a {
        width: 100%;
        text-align: center;
      }
    }
  }

  & .careerCoaches {
    &__submit-btn {
      width: 100%;
      border-radius: $br-radius-1;

      @include respond(xs) {
        width: 100%;
      }
    }

    &__edit_programs {
      width: 100%;

      &>div {
        padding: 0.7rem;
      }
    }

    &__edit-dialog {
      &-form {
        width: 100%;

        & .submit-btn {
          width: 100%;
          border-radius: $br-radius-1;
          margin-top: 2px;
        }
      }
    }

    & .assign-cohort-btn {
      width: 7rem;
    }

    &__institution-select {
      &>div {
        @include respond(md) {
          width: 100%;
        }
      }
    }

    &__program-filter {
      & .p-dropdown {
        @include respond(md) {
          width: 100%;
        }
      }

    }

    &__colgroup {
      & col {
        @include respond(laptops) {
          width: 135px;
        }

        @include respond(xs) {
          width: 106px;
        }
      }

      .canEdit-col {
        width: 99px;
      }
    }
  }

  .table-td {
    text-align: center;
  }

  .td-program {
    padding: 0 !important;
    text-align: center;
  }

}

@use 'sass:math';
@mixin lightmixin {

  // Variables

  //
  // custom-variables
  //

  // layout width


  $layout-width: 90%;

  //Rightbar Width
  $rightbar-width: 260px;

  //Topbar Background
  $bg-topbar-light: #ffffff;
  $bg-topbar-dark: #343b4a;

  // Topbar Height
  $topbar-height: 70px;

  // Secondary font
  $font-family-secondary: 'Barlow',
    sans-serif;

  // Font weight
  $font-weight-medium: 500;
  $font-weight-semibold: 600;

  // Dropdown Large
  $dropdown-lg-width: 320px;

  // Boxed layout width
  $boxed-layout-width: 1300px;
  $boxed-body-bg: #f8f3ef;

  //
  // Color system
  //

  // stylelint-disable
  $gray-100: #f1f5f7;
  $gray-200: #f7f7f7;
  $gray-300: #dee2e6;
  $gray-400: #ced4da;
  $gray-500: #adb5bd;
  $gray-600: #98a6ad;
  $gray-700: #6c757d;
  $gray-800: #414d5f;
  $gray-900: #323a46;

  $grays: ();
  $grays: map-merge(("100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900), $grays);

  $blue: #188ae3;
  $indigo: #4eb7eb;
  $purple: #6b5eae;
  $pink: #ff679b;
  $red: #f34943;
  $orange: #fd7e14;
  $yellow: #fbcc5c;
  $green: #31ce77;
  $teal: #02a8b5;
  $cyan: #35b8e0;


  $colors: ();
  $colors: map-merge(("blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800), $colors);

  $primary: $blue;
  $secondary: $secondary;
  $success: $green;
  $info: $cyan;
  $warning: $yellow;
  $danger: $red;
  $purple: $purple;
  $light: $gray-100;
  $dark: $gray-800;


  $theme-colors: ();
  $theme-colors: map-merge(("primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "pink": $pink,
        "purple": $purple), $theme-colors);


  // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
  $yiq-contrasted-threshold: 180;

  // Customize the light and dark text colors for use in our YIQ color contrast function.
  $yiq-text-dark: $gray-800;
  $yiq-text-light: $white;


  // Options
  //
  // Quickly modify global styling by enabling or disabling optional features.

  $enable-caret: false;


  // Spacing
  //
  // Control the default styling of most Bootstrap elements by modifying these
  // variables. Mostly focused on spacing.
  // You can add more entries to the $spacers map, should you need more variation.

  $spacer: 1.5rem;
  $spacers: ();
  $spacers: map-merge((0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)), $spacers);

  // This variable affects the `.h-*` and `.w-*` classes.
  $sizes: ();
  $sizes: map-merge((15: 15%,
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%), $sizes);


  // Body
  //
  // Settings for the `<body>` element.

  $body-bg: #f5f6f8;
  $body-color: $gray-700;


  // Links
  //
  // Style anchor elements.

  $link-color: $primary;
  $link-hover-color: darken($link-color, 15%);
  $link-hover-decoration: none;


  // Components
  //
  $component-active-bg: $primary;

  $caret-width: .25em;

  $box-shadow-sm: 0 0.75rem 6rem rgba(56, 65, 74, .03);
  $box-shadow: 0 3px 8px 0 rgba(154, 161, 171, 0.2);
  $box-shadow-lg: 0 1rem 3rem rgba($black, .12);


  // Grid columns
  //
  // Set the number of columns and specify the width of the gutters.

  $grid-columns: 12;
  $grid-gutter-width: 24px;


  // Fonts
  //
  // Font, line-height, and color for body text, headings, and more.

  // stylelint-disable value-keyword-case
  $font-family-sans-serif: 'Barlow',
    sans-serif;
  $font-family-monospace: SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace;
  $font-family-base: $font-family-sans-serif;

  // stylelint-enable value-keyword-case
  $font-size-base: 0.9rem;
  $font-size-lg: ($font-size-base * 1.25);
  $font-size-sm: ($font-size-base * .875);
  $font-weight-bold: 700;

  $headings-margin-bottom: $spacer;
  $headings-font-weight: 400;
  $headings-line-height: 1.1;

  $h1-font-size: 2.25rem;
  $h2-font-size: 1.875rem;
  $h3-font-size: 1.5rem;
  $h4-font-size: 1.125rem;
  $h5-font-size: 0.9375rem;
  $h6-font-size: 0.75rem;

  $display-line-height: $headings-line-height;

  $lead-font-size: ($font-size-base * 1.25);

  $small-font-size: 0.75rem;

  $text-muted: $gray-600;

  $blockquote-font-size: ($font-size-base * 1.25);

  $hr-border-color: $gray-200;

  $list-inline-padding: 6px;


  // Tables
  //
  // Customizes the `.table` component with basic values, each used across all table variations.

  $table-cell-padding: .85rem;
  $table-cell-padding-sm: .5rem;

  $table-color: $body-color;
  $table-hover-color: $table-color;

  $table-accent-bg: $gray-100;
  $table-hover-bg: $gray-100;

  $table-border-color: $gray-300;

  $table-head-bg: $gray-100;
  $table-head-color: $gray-700;

  $table-dark-bg: $gray-900;
  $table-dark-border-color: lighten($gray-900, 7.5%);
  $table-dark-color: $gray-600;


  // Buttons + Forms
  //
  // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

  $input-btn-padding-y: .45rem;
  $input-btn-padding-x: .90rem;
  $input-btn-font-size: $font-size-base;
  $input-btn-line-height: $line-height-base;

  $input-btn-focus-width: .15rem;
  $input-btn-focus-color: rgba($component-active-bg, .25);
  $input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

  $input-btn-padding-y-sm: .28rem;
  $input-btn-padding-x-sm: .8rem;
  $input-btn-font-size-sm: $font-size-sm;
  $input-btn-line-height-sm: $line-height-sm;

  $input-btn-padding-y-lg: .5rem;
  $input-btn-padding-x-lg: 1rem;
  $input-btn-font-size-lg: $font-size-lg;
  $input-btn-line-height-lg: $line-height-lg;

  $input-btn-border-width: $border-width;



  // Buttons
  //
  // For each of Bootstrap's buttons, define text, background, and border color.

  $btn-font-size: $input-btn-font-size;

  $btn-padding-y: $input-btn-padding-y;
  $btn-padding-x: $input-btn-padding-x;
  $btn-line-height: $input-btn-line-height;

  $btn-padding-y-sm: $input-btn-padding-y-sm;
  $btn-padding-x-sm: $input-btn-padding-x-sm;
  $btn-font-size-sm: $input-btn-font-size-sm;
  $btn-line-height-sm: $input-btn-line-height-sm;

  $btn-padding-y-lg: $input-btn-padding-y-lg;
  $btn-padding-x-lg: $input-btn-padding-x-lg;
  $btn-font-size-lg: $input-btn-font-size-lg;
  $btn-line-height-lg: $input-btn-line-height-lg;

  $btn-font-weight: $font-weight-normal;
  $btn-focus-width: 0.15rem;
  $btn-focus-box-shadow: $input-btn-focus-box-shadow;

  $btn-link-disabled-color: $gray-500;


  // Allows for customizing button radius independently from global border radius

  $btn-border-radius: .15rem;
  $btn-border-radius-lg: .15rem;
  $btn-border-radius-sm: .15rem;


  // Forms

  $input-padding-y: $input-btn-padding-y;
  $input-padding-x: $input-btn-padding-x;
  $input-font-size: $input-btn-font-size;
  $input-line-height: $input-btn-line-height;

  $input-padding-y-sm: $input-btn-padding-y-sm;
  $input-padding-x-sm: $input-btn-padding-x-sm;
  $input-font-size-sm: $input-btn-font-size-sm;
  $input-line-height-sm: $input-btn-line-height-sm;

  $input-padding-y-lg: $input-btn-padding-y-lg;
  $input-padding-x-lg: $input-btn-padding-x-lg;
  $input-font-size-lg: $input-btn-font-size-lg;
  $input-line-height-lg: $input-btn-line-height-lg;

  $input-disabled-bg: $gray-100;

  $input-color: $body-color;
  $input-border-width: 1px;
  $input-border-color: $gray-400;
  $input-border-radius: 0.2rem;

  $input-focus-box-shadow: none;
  $input-focus-border-color: darken($input-border-color, 10%);

  $input-placeholder-color: $gray-500;

  $input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
  $input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});

  $input-group-addon-border-color: $input-border-color;

  $custom-select-padding-y: .45rem;
  $custom-select-padding-x: .90rem;
  $custom-select-font-size: $input-font-size;
  $custom-select-height: $input-height;

  $custom-control-indicator-checked-color: $primary;
  $custom-control-indicator-checked-bg: $primary;

  $custom-select-border-width: $input-border-width;
  $custom-select-border-color: $input-border-color;
  $custom-select-border-radius: $border-radius;
  $custom-select-focus-border-color: $input-focus-border-color;
  $custom-select-focus-box-shadow: none;
  $custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
    $input-btn-focus-box-shadow;
  $custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

  $custom-range-track-width: 100%;
  $custom-range-track-height: .5rem;
  $custom-range-track-cursor: pointer;
  $custom-range-track-bg: $gray-300;
  $custom-range-track-border-radius: 1rem;
  $custom-range-track-box-shadow: inset 0 .25rem .25rem rgba($black, .1);

  $custom-range-thumb-width: 1rem;
  $custom-range-thumb-height: $custom-range-thumb-width;
  $custom-range-thumb-bg: $component-active-bg;
  $custom-range-thumb-border: 0;
  $custom-range-thumb-border-radius: 1rem;
  $custom-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1);
  $custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
    $input-btn-focus-box-shadow;
  $custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
  $custom-range-thumb-active-bg: lighten($component-active-bg, 35%);


  $custom-file-height: $input-height;
  $custom-file-height-inner: $input-height-inner;
  $custom-file-focus-border-color: $input-focus-border-color;
  $custom-file-focus-box-shadow: none;
  $custom-file-height-inner: $input-height-inner;

  $custom-file-padding-y: $input-btn-padding-y;
  $custom-file-padding-x: $input-btn-padding-x;
  $custom-file-line-height: $input-btn-line-height;
  $custom-file-color: $input-color;
  $custom-file-bg: $input-bg;
  $custom-file-border-width: $input-border-width;
  $custom-file-border-color: $input-border-color;
  $custom-file-border-radius: $input-border-radius;
  $custom-file-box-shadow: none;
  $custom-file-button-color: $custom-file-color;
  $custom-file-button-bg: $input-group-addon-bg;
  $custom-file-text: (en: "Browse"
    ) !default;


  // Form validation

  $form-feedback-margin-top: $form-text-margin-top;
  $form-feedback-font-size: $small-font-size;
  $form-feedback-valid-color: theme-color("success");
  $form-feedback-invalid-color: theme-color("danger");

  $form-feedback-icon-valid-color: $form-feedback-valid-color;
  $form-feedback-icon-valid: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23");
  $form-feedback-icon-invalid-color: $form-feedback-invalid-color;
  $form-feedback-icon-invalid: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23");

  $form-validation-states: ();
  // stylelint-disable-next-line scss/dollar-variable-default
  $form-validation-states: map-merge(("valid": ("color": $form-feedback-valid-color,
          "icon": $form-feedback-icon-valid),
        "invalid": ("color": $form-feedback-invalid-color,
          "icon": $form-feedback-icon-invalid),
      ),
      $form-validation-states);


  // Dropdowns
  //
  // Dropdown menu container and contents.

  $dropdown-padding-y: .25rem;
  $dropdown-border-color: darken($light, 3%);

  $dropdown-link-color: $body-color;
  $dropdown-link-hover-color: $headings-color;

  $dropdown-link-active-color: $dark;
  $dropdown-link-active-bg: $gray-200;

  $dropdown-item-padding-y: .45rem;
  $dropdown-item-padding-x: 1.2rem;

  $dropdown-header-color: inherit;



  // Navs
  $nav-pills-link-active-color: $component-active-color;
  $nav-pills-link-active-bg: $component-active-bg;


  // Pagination

  $pagination-color: $dark;

  $pagination-focus-box-shadow: $input-btn-focus-box-shadow;

  $pagination-hover-color: $dark;
  $pagination-active-bg: $component-active-bg;
  $pagination-active-border-color: $pagination-active-bg;


  // Cards

  $card-spacer-x: $spacer;
  $card-spacer-y: math.div($spacer * 2, 3);
  $card-border-width: 0;
  $card-border-color: $gray-200;
  $card-border-radius: .25rem;
  $card-cap-bg: lighten($gray-300, 5%);
  $card-columns-margin: $grid-gutter-width;
  $card-columns-gap: $grid-gutter-width;

  $card-group-margin: math.div($grid-gutter-width, 2);
  $card-deck-margin: $card-group-margin;


  // Tooltips
  $tooltip-font-size: $font-size-sm;
  $tooltip-border-radius: 0.2rem;
  $tooltip-padding-y: .4rem;
  $tooltip-padding-x: .8rem;
  $tooltip-font-size: $font-size-base;

  // Form tooltips must come after regular tooltips
  $form-feedback-tooltip-padding-y: $tooltip-padding-y;
  $form-feedback-tooltip-padding-x: $tooltip-padding-x;
  $form-feedback-tooltip-font-size: $tooltip-font-size;
  $form-feedback-tooltip-line-height: $line-height-base;
  $form-feedback-tooltip-opacity: $tooltip-opacity;
  $form-feedback-tooltip-border-radius: $tooltip-border-radius;

  // Popovers

  $popover-border-color: $gray-400;
  $popover-header-bg: $gray-100;
  $popover-border-color: $gray-300;
  $popover-border-radius: $border-radius;
  $popover-header-padding-y: .7rem;
  $popover-header-padding-x: .8rem;
  $popover-font-size: $font-size-base;


  // Badges

  $badge-font-weight: $font-weight-semibold;


  // Modals
  $modal-content-border-width: 0;
  $modal-content-border-color: transparent;
  $modal-content-border-radius: 0.2rem;
  $modal-backdrop-bg: $gray-900;


  // Progress bars

  $progress-height: 0.75rem;
  $progress-bar-bg: theme-color("primary");


  // List group

  $list-group-active-bg: $component-active-bg;


  // Breadcrumbs

  $breadcrumb-padding-y: math.div($spacer, 1.5);
  $breadcrumb-padding-x: 0;
  $breadcrumb-item-padding: .5rem;

  $breadcrumb-bg: transparent;
  $breadcrumb-divider: quote("\F142");
  $breadcrumb-divider-color: $gray-400;
  $breadcrumb-active-color: $gray-500;


  // Close
  $close-font-size: 1.4rem;
  $close-text-shadow: none;


  // Code

  $code-color: $pink;

  //
  // accordions.scss
  //

  .custom-accordion {
    .card {
      +.card {
        margin-top: 0.5rem;
      }
    }

    .accordion-arrow {
      font-size: 1.2rem;
    }

    a {
      &.collapsed {
        i.accordion-arrow {
          &:before {
            content: "\F142";
          }
        }
      }
    }
  }

  //
  // backgrounds.scss
  //


  @each $color,
  $value in $theme-colors {
    .bg-soft-#{$color} {
      background-color: rgba(($value), 0.25) !important;
    }
  }

  // Gradient
  .gradient-primary {
    background-image: linear-gradient(15deg, lighten($primary, 30%) 0%, $primary 100%);
  }

  .gradient-success {
    background-image: linear-gradient(15deg, $teal 0%, $green 100%);
  }

  .gradient-info {
    background-image: linear-gradient(15deg, lighten($info, 10%) 0%, $indigo 100%);
  }

  .gradient-warning {
    background-image: linear-gradient(15deg, lighten($warning, 10%) 0%, $warning 100%);
  }

  .gradient-danger {
    background-image: linear-gradient(15deg, lighten($danger, 10%) 0%, $pink 100%);
  }

  .gradient-dark {
    background-image: linear-gradient(15deg, lighten($dark, 10%) 0%, $dark 100%);
  }


  .gradient-widget {
    background-image: linear-gradient(15deg, $orange 0%, $yellow 100%);
  }

  .gradient-widget-2 {
    background-image: linear-gradient(15deg, darken($indigo, 30%) 0%, lighten($indigo, 5%) 100%);
  }

  .gradient-widget-3 {
    background-image: linear-gradient(15deg, $purple 0%, $pink 100%);
  }




  //
  // badge.scss
  //

  .badge {
    color: $white;
    font-family: $font-family-secondary;
  }

  .badge-light {
    color: $gray-800;
  }

  // badge lighten

  @each $color,
  $value in $theme-colors {
    .badge-light-#{$color} {
      background-color: rgba($value, 0.2);
      color: $value !important;
    }
  }


  //
  // breadcrumb.scss
  //


  // Breadcrumb item arrow
  .breadcrumb-item {
    >a {
      color: $gray-700;
    }

    +.breadcrumb-item {
      &::before {
        font-family: "Material Design Icons";
      }
    }
  }


  //
  // buttons.scss
  //

  .btn {
    color: $white;

    .mdi {
      &:before {
        margin-top: -1px;
      }
    }
  }

  .btn-rounded {
    border-radius: 2em;
  }

  //
  // light button
  //
  .btn-light,
  .btn-white {
    color: $gray-900;
  }

  //
  // Link buttons
  //
  // Make a button look and behave like a link
  .btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      background-color: transparent;
      border-color: transparent;
    }

    &:focus,
    &.focus {
      text-decoration: $link-hover-decoration;
      border-color: transparent;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
      pointer-events: none;
    }

    // No need for an active state here
  }

  //
  // Alternate buttons
  //
  @each $color,
  $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }

  //
  // Button labels
  //

  .btn-label {
    margin: -.55rem .9rem -.55rem -.9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900, 0.1);
  }

  .btn-label-right {
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
    padding: ($btn-padding-y) ($btn-padding-x);
    background-color: rgba($gray-900, 0.1);
    display: inline-block;
  }

  //
  // Button Extra Small Size
  //

  .btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
  }

  //
  // card.scss
  //

  .card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow-sm;
  }

  .card-drop {
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    color: inherit;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;

    &:hover {
      background-color: $gray-200;
      color: $dark;
    }
  }


  // Card title / Card Header
  .card-title,
  .card-header {
    margin-top: 0;
  }


  // Custom card box
  .card-box {
    background-color: $white;
    padding: $card-spacer-x;
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
  }

  .header-title {
    font-size: 17px;
    margin: 0 0 7px 0;
  }

  .sub-header {
    margin-bottom: $grid-gutter-width;
    color: $gray-600;
    font-size: 14px;
  }

  //
  // Custom-checkbox.scss
  //

  .checkbox {
    label {
      display: inline-block;
      padding-left: 8px;
      position: relative;
      font-weight: normal;
      margin-bottom: 0;

      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 2px solid $gray-600;
        content: "";
        display: inline-block;
        height: 18px;
        left: 0;
        margin-left: -18px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 18px;
        outline: none !important;
        top: 2px;
      }

      &::after {
        color: $gray-700;
        display: inline-block;
        font-size: 11px;
        height: 18px;
        left: 0;
        margin-left: -18px;
        padding-left: 3px;
        padding-top: 2px;
        position: absolute;
        top: 0;
        width: 18px;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled+label {
        opacity: 0.65;
      }
    }

    input[type="checkbox"]:focus+label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }

    input[type="checkbox"]:checked+label {
      &::after {
        content: "";
        position: absolute;
        top: 6px;
        left: 7px;
        display: table;
        width: 4px;
        height: 8px;
        border: 2px solid $gray-700;
        border-top-width: 0;
        border-left-width: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input[type="checkbox"]:disabled+label {
      &::before {
        background-color: $light;
        cursor: not-allowed;
      }
    }
  }

  .checkbox.checkbox-circle {
    label {
      &::before {
        border-radius: 50%;
      }
    }
  }

  .checkbox.checkbox-inline {
    margin-top: 0;
  }

  .checkbox.checkbox-single {
    input {
      height: 18px;
      width: 18px;
      position: absolute;
    }

    label {
      height: 18px;
      width: 18px;

      &:before {
        margin-left: 0;
      }

      &:after {
        margin-left: 0;
      }
    }
  }

  @each $color,
  $value in $theme-colors {
    .checkbox-#{$color} {
      input[type="checkbox"]:checked+label {
        &::before {
          background-color: $value;
          border-color: $value;
        }

        &::after {
          border-color: $white;
        }
      }
    }
  }

  //
  // custom-radio.scss
  //

  .radio {
    label {
      display: inline-block;
      padding-left: 8px;
      position: relative;
      font-weight: normal;
      margin-bottom: 0;

      &::before {
        -o-transition: border 0.5s ease-in-out;
        -webkit-transition: border 0.5s ease-in-out;
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $gray-600;
        content: "";
        display: inline-block;
        height: 18px;
        left: 0;
        margin-left: -18px;
        position: absolute;
        transition: border 0.5s ease-in-out;
        width: 18px;
        outline: none !important;
      }

      &::after {
        -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -webkit-transform: scale(0, 0);
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        background-color: $gray-700;
        border-radius: 50%;
        content: " ";
        display: inline-block;
        height: 10px;
        left: 6px;
        margin-left: -20px;
        position: absolute;
        top: 4px;
        transform: scale(0, 0);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        width: 10px;
      }
    }

    input[type="radio"] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled+label {
        opacity: 0.65;
      }
    }

    input[type="radio"]:focus+label {
      &::before {
        outline-offset: -2px;
        outline: 5px auto -webkit-focus-ring-color;
        outline: thin dotted;
      }
    }

    input[type="radio"]:checked+label {
      &::after {
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }
    }

    input[type="radio"]:disabled+label {
      &::before {
        cursor: not-allowed;
      }
    }
  }

  .radio.radio-inline {
    margin-top: 0;
  }

  .radio.radio-single {
    label {
      height: 17px;
    }
  }

  @each $color,
  $value in $theme-colors {
    .radio-#{$color} {
      input[type="radio"]+label {
        &::after {
          background-color: $value;
        }
      }

      input[type="radio"]:checked+label {
        &::before {
          border-color: $value;
        }

        &::after {
          background-color: $value;
        }
      }
    }
  }

  //
  // dropdown.scss
  //

  .dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    color: #6c757d;
    background-color: #fff;
    border: none;
    font-size: $font-size-base;
    position: absolute;
    z-index: 1000;

    &.show {
      top: 100% !important;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #414d5f;
    background-color: #F7F7F9;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #414d5f;
    background-color: #F7F7F9;
  }

  .dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
  }

  .dropdown-menu[x-placement^=right],
  .dropdown-menu[x-placement^=top],
  .dropdown-menu[x-placement^=left] {
    top: auto !important;
    animation: none !important;
  }

  @keyframes DropDownSlide {
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }

    0% {
      -webkit-transform: translateY(40px);
      transform: translateY(40px)
    }
  }


  // Dropdown Large (Custom)
  .dropdown-lg {
    width: $dropdown-lg-width;
  }

  @include media-breakpoint-down(sm) {
    .dropdown-lg {
      width: 200px !important;
    }
  }

  //
  // forms.scss
  //

  // Form-control light
  .form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
  }


  // Form elements (Color and Range)
  input.form-control[type="color"],
  input.form-control[type="range"] {
    min-height: 39px;
  }


  // Custom select
  .custom-select.is-invalid,
  .form-control.is-invalid,
  .custom-select:invalid,
  .form-control:invalid,
  .custom-select.is-valid,
  .form-control.is-valid,
  .custom-select:valid,
  .form-control:valid {
    &:focus {
      box-shadow: none !important;
    }
  }

  // Comment box
  .comment-area-box {
    .form-control {
      border-color: $gray-300;
      border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
      background-color: $gray-100;
      padding: 10px;
      border: 1px solid $gray-300;
      border-top: none;
      border-radius: 0 0 $input-border-radius $input-border-radius;
    }
  }

  // Input
  input {
    &:focus {
      outline: none;
    }
  }


  //
  // helper.scss
  //

  // Minimum width

  .width-xs {
    min-width: 80px;
  }

  .width-sm {
    min-width: 95px;
  }

  .width-md {
    min-width: 110px;
  }

  .width-lg {
    min-width: 140px;
  }

  .width-xl {
    min-width: 160px;
  }


  // Font Family
  .font-family-secondary {
    font-family: $font-family-secondary;
  }

  // avatar height
  .avatar-xs {
    height: 1.5rem;
    width: 1.5rem;
  }

  .avatar-sm {
    height: 2.25rem;
    width: 2.25rem;
  }

  .avatar-md {
    height: 3.5rem;
    width: 3.5rem;
  }

  .avatar-lg {
    height: 4.5rem;
    width: 4.5rem;
  }

  .avatar-xl {
    height: 6rem;
    width: 6rem;
  }

  .avatar-xxl {
    height: 7.5rem;
    width: 7.5rem;
  }

  .avatar-title {
    align-items: center;
    color: $white;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .avatar-group {
    padding-left: 12px;

    .avatar-group-item {
      margin: 0 0 10px -12px;
      display: inline-block;
      border: 2px solid $white;
      border-radius: 50%;
    }
  }


  // Font weight help class

  .font-weight-medium {
    font-weight: 500;
  }

  .font-weight-semibold {
    font-weight: 600;
  }


  // Text specify lines (Only chrome browser support)

  .sp-line-1,
  .sp-line-2,
  .sp-line-3,
  .sp-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .sp-line-1 {
    -webkit-line-clamp: 1;
  }

  .sp-line-2 {
    -webkit-line-clamp: 2;
  }


  .sp-line-3 {
    -webkit-line-clamp: 3;
  }

  .sp-line-4 {
    -webkit-line-clamp: 4;
  }

  // pull in

  .pull-in {
    margin-left: -$card-spacer-x;
    margin-right: -$card-spacer-x;
  }

  //
  // modal.scss
  //


  // Title text within header
  .modal-title {
    margin-top: 0;
  }

  // Full modal
  .modal-full {
    width: 92%;
    max-width: none;
  }

  // Custom animation modal
  .modal-demo {
    background-color: $white;
    width: 600px !important;
    border-radius: 4px;
    display: none;
    position: relative;

    .close {
      position: absolute;
      top: 15px;
      right: 25px;
      color: $light;
    }
  }

  @media (max-width: 768px) {
    .modal-demo {
      width: 96% !important;
    }
  }

  .custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: $dark;
    color: $white;
    text-align: left;
    margin: 0;
  }

  .custom-modal-text {
    padding: 20px;
  }

  .custombox-modal-wrapper {
    text-align: left;
  }

  .custombox-modal-flash,
  .custombox-modal-rotatedown {
    .close {
      top: 20px;
      z-index: 9999;
    }
  }

  //
  // nav.scss
  //

  .nav-tabs,
  .nav-pills {
    >li {
      >a {
        color: $gray-700;
        font-weight: $font-weight-medium;
      }
    }
  }

  .nav-pills {
    >a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }


  //Navtab custom
  .navtab-bg {
    li {
      >a {
        background-color: $gray-200;
        margin: 0 5px;
      }
    }
  }


  // Nav bordered

  .nav-bordered {
    border-bottom: 2px solid rgba($gray-600, 0.2) !important;

    .nav-item {
      margin-bottom: -2px;
    }

    li {
      a {
        border: 0 !important;
        padding: 10px 20px !important;
      }
    }

    a.active {
      border-bottom: 2px solid $primary !important;
    }
  }

  .tab-content {
    padding: 20px 0 0 0;
  }

  //
  // pagination.scss
  //


  // Pagination rounded (Custom)
  .pagination-rounded {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
    }
  }

  //
  // popover.scss
  //

  .popover-header {
    margin-top: 0;
  }


  //
  // print.scss
  //

  // Used invoice page
  @media print {

    .left-side-menu,
    .right-bar,
    .page-title-box,
    .navbar-custom,
    .footer {
      display: none;
    }

    .card-body,
    .content-page,
    .right-bar,
    .content,
    body {
      padding: 0;
      margin: 0;
    }
  }


  //
  // progress.scss
  //

  // Custom height

  // Progress height small
  .progress-sm {
    height: 5px;
  }


  // Progress height medium
  .progress-md {
    height: 8px;
  }


  // Progress height large
  .progress-lg {
    height: 12px;
  }

  // Progress height extra large
  .progress-xl {
    height: 15px;
  }

  // Progress height dubble extra large
  .progress-xxl {
    height: 25px;
  }


  /* Progressbar Vertical */
  .progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
      width: 100%;
    }

    &.progress-xl {
      width: 15px;
    }

    &.progress-lg {
      width: 12px;
    }

    &.progress-md {
      width: 8px;
    }

    &.progress-sm {
      width: 5px;
    }
  }

  .progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
      width: 100%;
      bottom: 0;
      position: absolute;
    }

    &.progress-xl {
      width: 15px;
    }

    &.progress-lg {
      width: 12px;
    }

    &.progress-md {
      width: 8px;
    }

    &.progress-sm {
      width: 5px;
    }
  }

  // Progress with percentage

  .progress-w-percent {
    min-height: 20px;
    margin-bottom: 20px;

    .progress {
      width: 80%;
      float: left;
      margin-top: 8px;
    }

    .progress-value {
      width: 20%;
      float: right;
      text-align: right;
      line-height: 20px;
    }
  }

  //
  // reboot.scss
  //


  // a
  a:focus {
    outline: none;
  }

  // Forms
  label {
    font-weight: $font-weight-medium;
    // color: #fff;
  }

  // Buttons
  button:focus {
    outline: none;
  }

  // blockquote

  .blockquote {
    padding: 10px 20px;
    margin-bottom: 20px;
    border-left: 4px solid $gray-200;

    &.blockquote-reverse {
      border-left: 0;
      border-right: 4px solid $gray-200;
      text-align: right;
    }
  }

  //
  // ribbons.scss
  //

  .ribbon-box {
    position: relative;

    .ribbon {
      position: relative;
      clear: both;
      padding: 5px 12px;
      margin-bottom: 15px;
      box-shadow: 2px 5px 10px rgba($dark, 0.15);
      color: $white;
      font-size: 13px;
      font-weight: $font-weight-semibold;

      &:before {
        content: " ";
        border-style: solid;
        border-width: 10px;
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        margin-bottom: -10px;
        z-index: -1;
      }

      &.float-left {
        margin-left: -30px;
        border-radius: 0 3px 3px 0;
      }

      &.float-right {
        margin-right: -30px;
        border-radius: 3px 0 0 3px;

        &:before {
          right: 0;
        }
      }

      &.float-center {

        span {
          margin: 0 auto 20px auto;
        }
      }

    }

    .ribbon-icon {
      position: absolute;
      right: 10px;
      top: -18px;
    }

    .ribbon-content {
      clear: both;
    }

    @each $color,
    $value in $theme-colors {
      .ribbon-#{$color} {
        background: ($value);

        &:before {
          border-color: darken(($value), 10%) transparent transparent;
        }
      }
    }


    /* Ribbon two */
    .ribbon-two {
      position: absolute;
      left: -5px;
      top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 75px;
      height: 75px;
      text-align: right;

      span {
        font-size: 13px;
        color: $white;
        text-align: center;
        line-height: 20px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        display: block;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
        position: absolute;
        top: 19px;
        left: -21px;
        font-weight: $font-weight-semibold;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          z-index: -1;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 100%;
          z-index: -1;
          border-left: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }
      }
    }

    @each $color,
    $value in $theme-colors {
      .ribbon-two-#{$color} {

        span {
          background: ($value);

          &:before {
            border-left: 3px solid darken(($value), 15%);
            border-top: 3px solid darken(($value), 15%);
          }

          &:after {
            border-right: 3px solid darken(($value), 15%);
            border-top: 3px solid darken(($value), 15%);
          }
        }
      }
    }
  }


  //
  // social.scss
  //

  .social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: block;
    border: 2px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
  }

  //
  // tables.scss
  //


  //Table centered (Custom)
  .table-centered {

    td,
    th {
      vertical-align: middle !important;
    }
  }


  // Custom table components (Custom)
  .table {
    th {
      font-weight: $font-weight-semibold;
    }

    .table-user {
      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  .action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: $gray-700;
    }
  }


  //
  // type.scss
  //

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-weight: $font-weight-bold;
    font-family: $font-family-secondary;
    color: $dark;
  }

  p {
    line-height: 1.8;
  }

  // Font sizes (Custom)
  .font-13 {
    font-size: 13px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-17 {
    font-size: 17px !important;
  }

  .font-18 {
    font-size: 18px !important;
  }

  .font-19 {
    font-size: 19px !important;
  }

  .font-20 {
    font-size: 20px !important;
  }

  .font-22 {
    font-size: 22px !important;
  }

  .font-24 {
    font-size: 24px !important;
  }



  //
  // footer.scss
  //

  .footer {
    bottom: 0;
    padding: 19px 15px 20px;
    position: absolute;
    right: 0;
    color: $gray-600;
    left: 0;
    background-color: darken($body-bg, 1.7%);
  }

  @include media-breakpoint-down(sm) {
    .footer {
      left: 0 !important;
      text-align: center;
    }
  }

  //
  // general.scss
  //

  html {
    position: relative;
    min-height: 100%;
  }

  body {
    padding-bottom: 60px;
    overflow-x: hidden;
  }

  //
  // horizontal-nav.scss
  //

  // Container width
  @include media-breakpoint-up(xl) {
    .container-fluid {
      max-width: $layout-width;
    }
  }

  // Content start wrapper
  .wrapper {
    padding: ($topbar-height + 70px) (math.div($grid-gutter-width, 2)) 0 (math.div($grid-gutter-width, 2));
  }

  // Top header
  #topnav {
    background: $bg-topbar-dark;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1001;
    padding: 0 (math.div($grid-gutter-width, 2));

    // Menu item arrow
    .arrow-down {
      display: inline-block;

      &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 5px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
      }
    }

    // Navbar Toggle
    .navbar-toggle {
      border: 0;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;

      .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 15px 20px 0 20px;
        padding-top: 13px;
        height: 44px;
        transition: all .5s ease;
      }

      span {
        height: 2px;
        width: 100%;
        background-color: $gray-300;
        display: block;
        margin-bottom: 5px;
        transition: transform .5s ease;
      }

      &.open {
        span {
          position: absolute;

          &:first-child {
            top: 18px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            visibility: hidden;
          }

          &:last-child {
            width: 100%;
            top: 18px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }

    // Active Menu color
    .has-submenu {
      &.active {
        >a {
          color: $white;
        }

        .submenu {
          li.active>a {
            color: $primary;
          }
        }
      }

      .has-submenu.active {
        >a {
          &:before {
            display: none;
          }
        }
      }
    }

    .topbar-menu {
      margin-top: $topbar-height;
    }
  }

  .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    >li {
      float: left;
      position: relative;

      >a {
        display: block;
        color: rgba($white, 0.7);
        font-size: 15px;
        font-family: $font-family-secondary;
        transition: all .5s ease;
        line-height: 20px;
        padding: 20px;
        position: relative;

        i {
          font-size: 14px;
          margin-right: 8px;
          vertical-align: middle;
          line-height: 0.875rem;
        }

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  /*
Responsive Menu
*/

  @media (min-width: 992px) {
    .navigation-menu {
      >li {
        >a {

          &:hover,
          &:focus,
          &:active {
            color: $white;
          }
        }

        &:hover {
          a {
            color: $white;
          }
        }

        &:first-of-type>a {
          padding-left: 0;
        }

        &.last-elements {
          .submenu {
            left: auto;
            right: 0;

            >li.has-submenu {
              .submenu {
                left: auto;
                right: 100%;
                margin-left: 0;
                margin-right: 10px;
              }
            }
          }
        }

        >ul {
          >li {
            &.has-submenu {

              &:active,
              &:hover {
                >a {
                  color: $primary;
                }
              }
            }
          }
        }

        .submenu {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1000;
          padding: 10px 0;
          list-style: none;
          min-width: 310px;
          text-align: left;
          visibility: hidden;
          opacity: 0;
          margin-top: 10px;
          border-radius: 4px;
          transition: all .2s ease;
          background-color: $white;
          box-shadow: rgba($dark, 0.15) 0px 0px 40px 0px;

          &:before {
            left: 16px;
            top: -10px;
            content: "";
            display: block;
            position: absolute;
            background-color: transparent;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid $white;
            z-index: 9999;
          }

          li {
            position: relative;

            ul {
              list-style: none;
              padding-left: 0;
              margin: 0;
            }

            a {
              display: block;
              padding: 9px 25px;
              clear: both;
              white-space: nowrap;
              color: $dark;

              &:hover {
                color: $primary;
              }
            }

            &.has-submenu {
              >a {
                .arrow-down {
                  &:after {
                    right: 20px !important;
                    transform: rotate(-135deg) translateY(-50%) !important;
                    position: absolute;
                  }
                }
              }
            }

            .submenu {
              left: 100%;
              top: 0;
              margin-left: 20px;
              margin-top: -1px;

              &:before {
                left: -20px;
                top: 10px;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-right: 12px solid $white;
              }
            }
          }

          &.megamenu {
            white-space: nowrap;
            width: auto;

            >li {
              overflow: hidden;
              width: 320px;
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
    }

    .navbar-toggle {
      display: none !important;
    }

    #navigation {
      display: block !important;
    }
  }

  @media (min-width: 768px) {
    .navigation-menu {
      >li {
        &.has-submenu {
          &:hover {
            >.submenu {
              visibility: visible;
              opacity: 1;
              margin-top: 0;

              >li {
                &.has-submenu {
                  &:hover {
                    >.submenu {
                      visibility: visible;
                      opacity: 1;
                      margin-left: 10px;
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }

          &.last-elements {
            >.submenu {
              &:before {
                right: 11px;
                left: auto;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .wrapper {
      padding-top: $topbar-height;
    }

    #topnav {

      .navigation-menu {
        float: none;
        max-height: 400px;
        text-align: left;

        >li {
          display: block;
          float: none;

          >a {
            color: $dark;
            padding: 15px;

            i {
              display: inline-block;
              margin-right: 10px;
              margin-bottom: 0;
              vertical-align: inherit;
            }

            &:after {
              position: absolute;
              right: 15px;
            }
          }

          .submenu {
            display: none;
            list-style: none;
            padding-left: 20px;
            margin: 0;

            li {
              a {
                display: block;
                position: relative;
                padding: 7px 20px;
                color: $dark;

                &:hover {
                  color: $primary;
                }
              }
            }

            &.open {
              display: block;
            }

            .submenu {
              display: none;
              list-style: none;

              &.open {
                display: block;
              }
            }

            &.megamenu {
              >li {
                >ul {
                  list-style: none;
                  padding-left: 0;
                }
              }
            }
          }

          &.has-submenu.open>a {
            color: $primary;
          }
        }
      }

      .has-submenu {
        &.active {
          a {
            color: $primary;
          }

          .submenu {
            li.active>a {
              color: $primary;
            }
          }
        }
      }

      .arrow-down {
        &:after {
          right: 15px;
          position: absolute;
        }
      }

      .navbar-header {
        float: left;
      }
    }

    #navigation {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      display: none;
      height: auto;
      padding-bottom: 0;
      overflow: auto;
      box-shadow: $box-shadow;
      background-color: $white;

      &.open {
        display: block;
        overflow-y: auto;
      }
    }
  }

  @media (max-height: 375px) {
    .navigation-menu {
      max-height: 240px !important;
    }
  }

  // Light Menubar
  .topbar-light {
    .navbar-custom {
      background: $bg-topbar-light;

      .app-search {
        .form-control {
          background-color: rgba($dark, 0.05);
          color: $gray-600;
        }
      }

      .topnav-menu {
        .nav-link {
          color: $gray-700;
        }
      }
    }

    #topnav {
      .topbar-menu {
        padding-bottom: 0px;
      }

      .navbar-toggle {
        span {
          background-color: $gray-700;
        }
      }
    }

    @media (min-width: 992px) {
      .wrapper {
        padding-top: 130px;
      }
    }
  }



  // Center Menu

  .center-menu {
    @media (min-width: 992px) {
      #topnav {
        .navigation-menu {
          text-align: center;

          >li {
            display: inline-block;
            float: none;

            >a {
              padding: 20px;

              i {
                display: block;
                margin: 0 0 5px 0;
                float: none;
                text-align: center;
              }

              .arrow-down {
                display: none;
                margin: 0 0 5px 0;
              }
            }
          }
        }
      }

      .wrapper {
        padding: ($topbar-height + 80px) (math.div($grid-gutter-width, 2)) 0 (math.div($grid-gutter-width, 2));
      }
    }
  }

  // Unsticky Header
  .unsticky-header {
    // @media (min-width: 992px) {

    #topnav,
    .navbar-custom {
      position: absolute;
    }

    // }
  }


  // Boxed layout
  .boxed-layout {
    .container-fluid {
      max-width: $boxed-layout-width;
    }
  }

  //
  // layouts.scss
  //

  body {
    &.boxed-layout {
      .container-fluid {
        max-width: $boxed-layout-width;
      }
    }
  }

  //
  // page-title.scss
  //

  .page-title-alt-bg {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    // background-color: $bg-topbar-dark;
  }

  .page-title-box {
    position: relative;
    background-color: $white;
    padding: 0 20px;
    border-radius: 5px;
    margin-bottom: 30px;

    .page-title {
      font-size: 1.125rem;
      color: $gray-900;
      line-height: 50px;
      margin: 0px;
      text-align: center;
    }

    .page-title-right {
      float: right;
    }

    .breadcrumb {
      margin-bottom: 0;
      padding: 14px 0;

      a {
        color: $text-muted;
      }
    }
  }


  @include media-breakpoint-down(sm) {
    .page-title-box {
      .page-title {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .breadcrumb {
        display: none;
      }
    }
  }

  @media (max-width: 640px) {
    .page-title-box {
      .page-title-right {
        display: none;
      }
    }
  }

  @media (max-width: 419px) {
    .page-title-box .breadcrumb {
      display: none;
    }
  }

  //
  // right-sidebar.scss
  //

  .right-bar {
    background-color: $white;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;
    padding-bottom: 100px;

    .rightbar-title {
      background-color: $primary;
      padding: 27px 25px;
      color: $white;
    }

    .right-bar-toggle {
      background-color: lighten($dark, 7%);
      height: 24px;
      width: 24px;
      line-height: 24px;
      color: $white;
      text-align: center;
      border-radius: 50%;
      margin-top: -4px;

      &:hover {
        background-color: lighten($dark, 10%);
      }
    }

    .user-box {
      padding: 25px;
      text-align: center;

      .user-img {
        position: relative;
        height: 64px;
        width: 64px;
        margin: 0 auto 15px auto;

        .user-edit {
          position: absolute;
          right: -5px;
          bottom: 0px;
          height: 24px;
          width: 24px;
          background-color: $white;
          line-height: 24px;
          border-radius: 50%;
          box-shadow: $box-shadow-lg;
        }
      }

      h5 {
        margin-bottom: 2px;

        a {
          color: $dark;
        }
      }
    }
  }

  // Rightbar overlay
  .rightbar-overlay {
    background-color: rgba($dark, 0.55);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
  }

  .right-bar-enabled {
    .right-bar {
      right: 0;
    }

    .rightbar-overlay {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    .right-bar {
      overflow: auto;

      .slimscroll-menu {
        height: auto !important;
      }
    }
  }


  // Inbox-widget(Used Profile)
  .inbox-widget {
    .inbox-item {
      overflow: hidden;
      padding: 0.625rem 0;
      position: relative;

      .inbox-item-img {
        display: block;
        float: left;
        margin-right: 15px;

        img {
          width: 40px;
        }
      }

      .inbox-item-author {
        color: $dark;
        display: block;
        margin-bottom: 0px;
        font-weight: $font-weight-medium;
      }

      .inbox-item-text {
        color: $gray-600;
        display: block;
        margin: 0;
        overflow: hidden;
      }

      .inbox-item-date {
        color: $gray-600;
        font-size: 0.6875rem;
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }

  }

  //
  // topbar.scss
  //

  // Logo
  .logo {
    display: block;
    line-height: $topbar-height;

    span.logo-lg {
      display: block;
    }

    span.logo-sm {
      display: none;
    }

    .logo-lg-text-dark {
      color: $dark;
      font-weight: $font-weight-bold;
      font-size: 22px;
      text-transform: uppercase;
    }

    .logo-lg-text-light {
      color: $white;
      font-weight: $font-weight-bold;
      font-size: 22px;
      text-transform: uppercase;
    }
  }

  .logo-box {
    padding-right: 40px;
    padding-left: 12px;
  }

  .navbar-custom {
    position: fixed;
    left: 0;
    right: 0;
    height: $topbar-height;
    z-index: 100;

    .topnav-menu {
      >li {
        float: left;
      }

      .nav-link {
        padding: 0 15px;
        color: rgba($white, 0.8);
        min-width: 32px;
        display: block;
        line-height: $topbar-height;
        text-align: center;
        max-height: $topbar-height;
      }
    }

    .menu-left {
      overflow: hidden;
    }

    .dropdown.show {
      .nav-link {
        background-color: rgba($dark, 0.05);
      }
    }

    /* Search */
    .app-search {
      padding-top: 16px;
      padding-right: 20px;

      .form-control {
        border: none;
        height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        color: $white;
        background-color: rgba($white, 0.05);
        box-shadow: none;
        min-width: 180px;
        width: 50%;
      }
    }

    .button-menu-mobile {
      border: none;
      color: $dark;
      display: inline-block;
      height: $topbar-height;
      line-height: $topbar-height;
      width: 60px;
      background-color: transparent;
      font-size: 24px;
      cursor: pointer;

      span {
        width: 18px;
        height: 2px;
        background-color: $dark;
        display: block;
        margin: 5px 12px;

        &:nth-of-type(2) {
          width: 24px;
        }
      }
    }

    .button-menu-mobile.disable-btn {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    body {
      padding-bottom: 80px;
    }

    .pro-user-name {
      display: none;
    }

    .navbar-custom {
      padding: 0 0 0 (math.div($grid-gutter-width, 2));
    }

    .logo-box {
      padding-right: 0;
      padding-left: 0;

      .logo-lg {
        display: none !important;
      }

      .logo-sm {
        display: block !important;
      }
    }
  }

  @media (min-width: 992px) {
    #topnav {
      .topbar-menu {
        padding-bottom: 10px;
      }
    }
  }


  /* Notification */
  .noti-scroll {
    max-height: 230px;
  }

  .notification-list {
    margin-left: 0;

    .noti-title {
      background-color: $white;
      padding: 15px 20px;
    }

    .noti-icon {
      font-size: 21px;
      vertical-align: middle;
    }

    .noti-icon-badge {
      display: inline-block;
      position: absolute;
      top: 14px;
      right: 8px;
    }

    .notify-item {
      padding: 12px 20px;

      .notify-icon {
        float: left;
        height: 36px;
        width: 36px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
        color: $white;
      }

      .notify-details {
        margin-bottom: 5px;
        overflow: hidden;
        margin-left: 45px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $gray-800;
        font-weight: $font-weight-medium;


        b {
          font-weight: 500;
        }

        small {
          display: block;
        }

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
        }
      }

      .user-msg {
        margin-left: 45px;
        white-space: normal;
        line-height: 16px;
      }
    }

    .profile-dropdown {
      .notify-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      }
    }
  }

  .profile-dropdown {
    width: 170px;

    i {
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .nav-user {
    padding: 0 12px !important;

    img {
      height: 32px;
      width: 32px;
    }
  }


  .headerLink {
    color: #414d5f !important;
  }

  .headerLink:focus,
  .headerLink:hover {
    color: #272e37 !important;
  }



  .p-datatable .p-datatable-thead>tr>th {
    background-color: white;
    color: #6c757d;
  }

  .p-datatable .p-datatable-caption,
  .p-datatable .p-datatable-summary {
    background-color: white;
    border: none;
  }

  .p-paginator {
    background-color: inherit;
    border: inherit;
    padding: 0;
  }

  .p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background-color: #f1f5f7;

  }

  .p-panel .p-panel-titlebar {
    background-color: #f1f5f7;
  }

  .inactive {
    background-color: #ef8888 !important;
  }



  ul.timeline_new>li:before {
    background: #ffffff !important;
  }

  .timeline:not(.timeline--horizontal):before {
    background-color: #35b8e0;
    bottom: 0;
    content: '';
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 2px;
    z-index: 1;
  }

  .timeline__content {
    background-color: #f5f6f8;
    border: none;
    color: inherit;
    display: block;
    padding: 1.25rem;
    position: relative;
  }


  .timeline__content:before {
    border-bottom: 10px solid transparent;
    border-left: 12px solid #f5f6f8;
    border-top: 10px solid transparent;
  }

  .timeline__content:after {
    border-bottom: 10px solid transparent;
    border-left: 12px solid #f5f6f8;
    border-top: 10px solid transparent;

  }

  .timeline__item--right .timeline__content:after {
    border-bottom: 9px solid transparent;
    border-right: 11px solid #f5f6f8;
    border-left: none;
    border-top: 9px solid transparent;
  }

  .timeline__item--right .timeline__content:before {
    border-bottom: 10px solid transparent;
    border-right: 12px solid #f5f6f8;
    border-left: none;
    border-top: 10px solid transparent;
  }


  .timeline__item:after {
    background: #ffffff;
    border: 3px solid #35b8e0;
    border-radius: 50%;
    content: '';
    height: 20px;
    position: absolute;
    right: -10px;
    transform: translateY(-50%);
    top: 50%;
    width: 20px;
    z-index: 1;
  }



  .p-dialog .p-dialog-header {
    background-color: #fff;
  }

  .p-fileupload .p-fileupload-buttonbar,
  .p-fileupload .p-fileupload-conten {
    background: none;
    border: none;
  }


  .p-fileupload .p-fileupload-content {
    background: none;
    border: none;
  }


  .borderRight {
    border-right: 1px solid #f4f5f7;
  }

  .form-control,
  .p-inputtext {
    border: 1px solid #a6a6a6;
  }

  .alfredTableColumn {
    background-color: #dfe3e5 !important;
  }

  .reportTableColumn {
    background-color: #c5f1d9 !important;
  }

  .varianceTableColumn {
    background-color: #fcccca !important;
  }


  // #quickCohortView>div>div.p-datatable-summary.p-widget-header.ng-star-inserted,
  // #quickCohortView>div>div.p-datatable-caption.p-widget-header.ng-star-inserted {
  //   border: 1px solid #c8c8c8;
  // }

  .headerBoldTable {
    background-color: rgb(255 247 181 / 70%) !important;
  }

  .hideLoadingTDs>td {
    background-color: black;
  }


  .search-icon {
    color: #4c4c4c;
  }


  .table-lead-position th,
  .table-lead-position td,
  .table-lead-position thead th,
  .table-lead-position tbody+tbody {
    border-color: #88d3a9;
  }

  .table-lead-position,
  .table-lead-position>th,
  .table-lead-position>td {
    background-color: #abeac7;
  }

  .table-secondary-position th,
  .table-secondary-position td,
  .table-secondary-position thead th,
  .table-secondary-position tbody+tbody {
    border-color: #add2ee
  }

  .table-secondary-position,
  .table-secondary-position>th,
  .table-secondary-position>td {
    background-color: #c2e5ff;
  }

  .table-ta-position th,
  .table-ta-position td,
  .table-ta-position thead th,
  .table-ta-position tbody+tbody {
    border-color: #ead7a8;
  }

  .table-ta-position,
  .table-ta-position>th,
  .table-ta-position>td {
    background-color: #ffeec5;
  }


  .light-color{
    color: $gray-700;
  }

  .dark-color{
    color: $gray-500;
  }
}

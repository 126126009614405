app-confirm-step {
  $root: &;

    & .sidebar-component .p-sidebar {
    background-color: white !important;
    border: unset !important;
    }

    & .color-scheme-dark .sidebar-component .p-sidebar {
    background-color: #363f4c !important;
    border: unset !important;
    }

    & .confirm-step-component .color-scheme-dark .unselected-filter-button {
        background-color: transparent;
        border: 1px solid #fff2;
        color: #fff2;
    }

}

body.color-scheme-dark {
    app-confirm-step {
    $root: &;

        & .sidebar-component .p-sidebar {
        background-color: #363f4c !important;
        border: unset !important;
        }

        & .unselected-filter-button {
            background-color: transparent;
            border: 1px solid #fff2;
            color: #fff2;
        }

    }
}


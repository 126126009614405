app-course-request {
  $root: &;

  .Lead {
    background-color: #00a806;
    color: #fff;
  }

  .Associate {
    background-color: #b58416;
    color: #fff;
  }

  span.position-rounded {
    padding: 0.2rem 1rem;
    border-radius: 6px;
    display: block;
    width: fit-content;
  }

  & app-table-plus-body {
    .tp-row {
      border: unset !important;
      border-left: unset !important;
      background: #373e4b !important;
      cursor: pointer;

      &:hover {
        background: #596272 !important;
      }

      .position {
        span.position-rounded {
          padding: 0.25rem 1rem;
          border-radius: 6px;
          display: block;
          width: fit-content;
        }

        .Lead {
          background-color: #00a806;
          color: #fff;
        }

        .Associate {
          background-color: #bd8508;
          color: #fff;
        }
      }
    }
  }

  .popup {
    width: max-content;
  }

  .link {
    color: #fff;
    text-decoration: underline;
  }

  .green {
    color: #29a55f;
  }
}

p-sidebar {
  & .p-sidebar {
    background: #363f4c;
    border: none;
  }

  & .cohort {
    background-color: #545f70;
    color: aliceblue;
  }

  & .program {
    background-color: #1b8ae3;
    color: aliceblue;
  }

  & .position {
    background-color: #00a806;
    color: aliceblue;
  }

  & .information-list {
    & li {
      background: #313844;
      color: #fff;
    }

    list-style: none;
  }

  & .box-information {
    overflow-y: scroll;
    height: 75vh;
  }

  & .close-panel {
    background-color: transparent;
    border: 1px solid #1563b7;
  }
}

app-default-popup {
  & .discard-button {
    background-color: #4d5865 !important;
    border: none !important;
  }

  & .discard-button:hover {
    transition: 0.4s all;
    background-color: #414b57 !important;
  }

  & .save-button {
    background-color: #356cad !important;
    border: 1px solid #356cad !important;
  }

  & .save-button-disabled {
    background-color: #ffffff00 !important;
    border: 1px solid #356cad !important;
  }

  & .color-text {
    color: #fff;
  }

  & .save-button:hover {
    transition: 0.4s all;
    background-color: #225797 !important;
    border: 1px solid #225797 !important;
  }

  & textarea {
    resize: none;
  }
}


app-instructor-table [data-varsion="2"]{
  & .btn-cancel-pending-call-out{
    width: 7em;
    text-align: center;
    display: inherit;
    padding: 0.25rem!important;
    background: #ba0000;
    border: 0px !important;
  }
  & .btn-call-out{
    width: 7em;
    text-align: center;
    display: inherit;
    padding: 0.25rem!important;
    background: none !important;
    border: 1px solid #ba0000 !important;

  }

  th, td, tr, tbody, table {
    border: none !important;
}
}
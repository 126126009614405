app-assign-institutions {
  $root: &;



}
#assign-relationships-listbox > div > div.p-listbox-list-wrapper{
  height: 20em;
}


#enrollmentsListBox > div > div.p-listbox-list-wrapper{
  height: 20em;
}

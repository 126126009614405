app-answers-for-questions {
  $root: &;

  & p-dropdown {
    & > div {
      width: 100%;
    }
  }

  .multiSelect {
      & > div {
        text-align: left;
        top: 0.9em;
        width: 100%;
      }
  }

  .calendar > span {
    width: 100%;
  }

  .calendar > span > input {
    width: 100%;
  }

  input {
    width: 100%;
  }
}

app-career-service-placement-priorities {
  $root: &;

  & .placementPriorities {
    padding: 1rem;
    &__filters {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      &>* {
        margin-right: 1.3rem;

        @include respond(md){
          margin-right: 0;
          width: 100%;
        }
      }
    }

    &__input {
      width: 15rem;
      height: 38px;

      @include respond(md){
        width: 100%;
      }
    }

    & p-multiselect {
      @include respond(md){
        width: 100%;
      }

      & > div {
        width: 15rem;

        @include respond (md){
          width: 100%;
          margin-top: 1rem;
        }

        & .p-multiselect-label-container {
          padding-right: 1em;
          text-align: left;

          @include respond (md){
            margin-right: 0;
          }
        }
      }
    }

    &__colgroup {
      & col {
        @include respond(laptops) {
          width: 135px;
        }

        @include respond(xs) {
          width: 106px;
        }
      }
    }
  }

  & .link_url {
    word-wrap: break-word;
  }

  .student_summary_text {
    padding: 1.5rem 0.5rem;
  }

  & p-paginator {
    padding: 1rem;

    & .p-paginator{
      padding: 1.5rem 0 0.5rem 0;
    }
  }

  & .empty-students {
    text-align: center;
  }

}

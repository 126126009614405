app-new-student-form {
  $root: &;

  & .p-dialog .p-dialog-header {
    text-align: center;
  }


  & .new_student_form {
    padding-top: 1rem;

    & p-dropdown  {
      & > div {
        width: 90%;
      }
    }

    &--checkbox {
      background-color: #f9f9f9;
      border-radius: 7px;
      padding: 1rem 1rem 1rem 1.3rem;
    }


    &--subtitle {
      padding: 2rem 0 1rem 0.5rem;
      font-size: $dsk-article-headline-1;
      font-weight: 600;


      &:last-of-type {
        padding-top: 1rem;
      }
    }

    &--box {
      display: flex;
      flex-wrap: wrap;
    }

    & .form-group {
      width: 50%;
      padding-left: 0.5rem;

      @include  respond(md) {
        width: 48%;
      }

      @include respond(xs) {
        width: 100%;
      }

      & .element {
        width: 19rem;
      }

      & .intro_letter {
        &>div {
          width: 90%;

          @include respond(laptops) {
            width: 92%;
          }

          @include respond(xs) {
            width: 100%;
          }
        }

        &--span {
          font-size: 13px;
        }
      }
    }

    &--btnContainer {
      width: 100%;
      text-align: right;
      margin: 1rem 0;

      & .btn {
        width: 35%;
      }
    }

  }

  & .p-widget .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    width: 21.5rem;
  }


  & .entry_point {
    & .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
      margin-top: -1em;
    }
  }

  & .p-widget .p-dropdown.p-dropdown-clearable {
    @include respond(md){
      width: 91%;
    }
  }
}

app-new-student-form {
  .newStudentDialog .p-dialog-mask{
    width: 100%;
  }

  .newStudentDialog .p-dialog{
    width: 70rem;
  }

}

body[class*="color-scheme-dark"] {
  app-new-student-form {
    .new_student_form--checkbox {
      background-color: unset;
    }
  }
}

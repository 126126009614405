

body.color-scheme-dark {
    app-instructors-step {
    $root: &;

        & .sidebar-component .p-sidebar {
            background-color: #363f4c !important;
            border: unset !important;
        }

    }
}
